import React from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { FAB } from 'react-native-paper';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { useDispatch, useSelector } from 'react-redux';
import { colors, getFontSize } from '../lib/constants';
import { ThemeType } from '../lib/types';
import {
    selectHaptics,
    selectTheme,
} from '../redux/settings/settings.selectors';
import { selectionHaptics } from '../utils/haptics';
import { selectLyrics } from '../redux/lyrics/lyrics.selectors';
import LyricItem from '../components/LyricItem';
import { setActiveLyric } from '../redux/temp-state/temp-state.action';
import { useDimensions } from '@react-native-community/hooks';

interface Props {
    navigation: any;
}

const LyricsScreen: React.FC<Props> = ({ navigation }) => {
    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);
    const lyrics = useSelector(selectLyrics);

    const dispatch = useDispatch();

    const styles = getStyleSheet(theme);

    const { width, height } = useDimensions().window;

    return (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: colors[theme].bg,
            }}
        >
            {lyrics.length ? (
                <FlatList
                    contentContainerStyle={{ paddingBottom: getBottomSpace() }}
                    indicatorStyle={theme === 'dark' ? 'white' : 'black'}
                    style={{ paddingBottom: getBottomSpace() }}
                    keyExtractor={({ id }) => id}
                    data={lyrics}
                    renderItem={({
                        item: { id, updatedAt, createdAt, title, content },
                    }) => (
                        <LyricItem
                            onPress={() => {
                                selectionHaptics(hapticsEnabled);
                                dispatch(
                                    setActiveLyric({
                                        id,
                                        title,
                                        content,
                                        updatedAt,
                                        createdAt,
                                    })
                                );
                                navigation.navigate('Lyric Editor');
                            }}
                            {...{
                                id,
                                title,
                                content,
                                updatedAt,
                                createdAt,
                            }}
                        />
                    )}
                />
            ) : (
                <>
                    <Text
                        style={{
                            color: colors[theme].txt,
                            fontSize: getFontSize(2.5, width, height),
                            fontWeight: 'bold',
                            marginBottom: 10,
                        }}
                    >
                        No lyrics found
                    </Text>
                    <Text
                        style={{
                            color: colors[theme].txt,
                            marginBottom: getBottomSpace(),
                        }}
                    >
                        Add a new lyric to get started
                    </Text>
                </>
            )}
            <FAB
                style={styles.fab}
                icon="plus"
                onPress={() => {
                    navigation.navigate('Lyric Editor');
                    selectionHaptics(hapticsEnabled);
                }}
            />
        </View>
    );
};

const getStyleSheet = (theme: ThemeType) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: colors[theme].bg,
            alignItems: 'center',
            justifyContent: 'center',
        },
        fab: {
            backgroundColor: colors[theme].fabBg,
            position: 'absolute',
            margin: 20,
            marginBottom: getBottomSpace() + 10,
            right: 0,
            bottom: 0,
        },
    });

export default LyricsScreen;
