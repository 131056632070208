import React, { useState, useEffect } from 'react';
import { StyleSheet, FlatList, View } from 'react-native';
import { Button } from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import { useSelector } from 'react-redux';
import { colors } from '../lib/constants';
import RowItem from '../components/RowItem';
import {
    selectTheme,
    selectHaptics,
} from '../redux/settings/settings.selectors';
import { ArticleType, ThemeType } from '../lib/types';
import { selectionHaptics } from '../utils/haptics';

interface Data {
    numSyllables: number;
    result_list: ArticleType[];
}

interface Props {
    initiallyCollapsed: boolean;
    data: Data;
    setActiveArticle: React.Dispatch<React.SetStateAction<null | ArticleType>>;
    setArticleModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SyllableView: React.FC<Props> = ({
    initiallyCollapsed,
    data,
    setActiveArticle,
    setArticleModalVisible,
}) => {
    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);

    const styles = getStyleSheet(theme);

    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => setCollapsed(initiallyCollapsed), [initiallyCollapsed]);

    const renderRow = (article: ArticleType, index: number) => (
        <View
            style={[
                styles.resultsContainer,
                {
                    borderBottomWidth:
                        (data.result_list.length % 2 === 0 &&
                            (index === data.result_list.length - 1 ||
                                index === data.result_list.length - 2)) ||
                        (data.result_list.length % 2 !== 0 &&
                            index === data.result_list.length - 1)
                            ? StyleSheet.hairlineWidth
                            : 0,
                },
            ]}
        >
            <RowItem
                {...{ article, setActiveArticle, setArticleModalVisible }}
            />
        </View>
    );

    return (
        <View style={styles.container}>
            <Button
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    margin: 10,
                    marginTop: 0,
                    marginBottom: 10,
                    borderColor: colors[theme].txt,
                }}
                color={colors[theme].txt}
                mode={collapsed ? 'outlined' : 'contained'}
                onPress={() => {
                    selectionHaptics(hapticsEnabled);
                    setCollapsed(!collapsed);
                }}
            >
                Syllable: {data.numSyllables}
            </Button>
            <Collapsible collapsed={collapsed}>
                <FlatList
                    indicatorStyle={theme === 'dark' ? 'white' : 'black'}
                    keyboardShouldPersistTaps="always"
                    contentContainerStyle={{ marginVertical: 10 }}
                    key={data.numSyllables}
                    removeClippedSubviews={false}
                    data={data.result_list}
                    keyExtractor={(item, index) =>
                        `${index}${item.word}${item.score}${item.numSyllables}`
                    }
                    renderItem={({ item, index }) => renderRow(item, index)}
                    scrollEnabled={false}
                    numColumns={2}
                />
            </Collapsible>
        </View>
    );
};

const getStyleSheet = (theme: ThemeType) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            paddingBottom: 10,
        },
        resultsContainer: {
            flex: 1,
            marginLeft: 20,
            marginRight: 20,
            borderTopWidth: StyleSheet.hairlineWidth,
            borderColor: colors[theme].borderColor,
        },
    });
};

export default SyllableView;
