import { ArticleType } from '../../lib/types';
import {
    SAVE_ARTICLE,
    REMOVE_ARTICLE,
    CLEAR_SAVED_ARTICLES,
} from './saved-articles.types';
import { saveArticle, removeArticle } from './saved-articles.utils';

interface ActionTypes {
    type: string;
    payload: ArticleType;
}

const INITIAL_STATE = {
    savedArticles: [],
};

const savedArticlesReducer = (state = INITIAL_STATE, action: ActionTypes) => {
    switch (action.type) {
        case SAVE_ARTICLE:
            return {
                ...state,
                savedArticles: saveArticle(state.savedArticles, action.payload),
            };
        case REMOVE_ARTICLE:
            return {
                ...state,
                savedArticles: removeArticle(
                    state.savedArticles,
                    action.payload
                ),
            };
        case CLEAR_SAVED_ARTICLES:
            return {
                ...state,
                savedArticles: [],
            };
        default:
            return state;
    }
};

export default savedArticlesReducer;
