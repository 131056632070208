import React, { Component, ErrorInfo } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import * as Linking from 'expo-linking';
import { Button } from 'react-native-paper';
import { colors } from '../lib/constants';
import { handleError } from '../utils/handleError';

export default class ErrorBoundary extends Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        handleError(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <View style={styles.container}>
                    <Text style={styles.text}>Something went wrong</Text>
                    <Text style={styles.description}>
                        Please press the "report" button to send me an email
                        about the issue.
                    </Text>
                    <View style={{ flexDirection: 'row' }}>
                        <Button
                            style={{ margin: 10, borderColor: colors.dark.txt }}
                            color={colors.dark.txt}
                            mode="outlined"
                            onPress={() => {
                                try {
                                    Linking.openURL(
                                        'mailto:andor.davoti@gmail.com'
                                    );
                                } catch (err) {
                                    handleError(err);
                                }
                            }}
                        >
                            Report
                        </Button>
                        <Button
                            style={{ margin: 10, borderColor: colors.dark.txt }}
                            color={colors.dark.txt}
                            mode="outlined"
                            onPress={() => {
                                this.setState({ hasError: false });
                            }}
                        >
                            Home
                        </Button>
                    </View>
                </View>
            );
        }
        return this.props.children;
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.dark.bg,
    },
    text: {
        fontSize: 20,
        textAlign: 'center',
        color: colors.dark.txt,
    },
    description: {
        margin: 15,
        marginLeft: 40,
        marginRight: 40,
        textAlign: 'left',
        color: colors.dark.txt,
    },
});
