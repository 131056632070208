import { LyricType } from '../../lib/types';

export const saveLyric = (savedLyrics: LyricType[], lyricToAdd: LyricType) => {
    if (!lyricToAdd.title?.trim() && !lyricToAdd.content?.trim()) {
        return removeLyric(savedLyrics, lyricToAdd);
    }

    const lyricIndexExists = savedLyrics.findIndex(
        savedLyric => savedLyric.id === lyricToAdd.id
    );

    if (lyricIndexExists === -1) {
        return [lyricToAdd, ...savedLyrics];
    } else {
        const updatedLyrics = [...savedLyrics];
        updatedLyrics[lyricIndexExists] = lyricToAdd;
        return updatedLyrics;
    }
};

export const removeLyric = (
    savedLyrics: LyricType[],
    lyricToRemove: LyricType
) => {
    const removedLyricsArray = savedLyrics.filter(
        savedLyric => savedLyric.id !== lyricToRemove.id
    );

    return removedLyricsArray;
};
