import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { calcFromHeight, colors, getFontSize } from '../lib/constants';
import { selectTheme } from '../redux/settings/settings.selectors';
import { useDimensions } from '@react-native-community/hooks/lib/useDimensions';

interface Props {
    index: number;
    numberOfDefinitions: number;
    definition: string;
}

const DefinitionItem: React.FC<Props> = ({
    index,
    numberOfDefinitions,
    definition,
}) => {
    const theme = useSelector(selectTheme);

    const { width, height } = useDimensions().window;
    return (
        <View
            style={{
                paddingBottom:
                    index === numberOfDefinitions - 1
                        ? 0
                        : calcFromHeight(5, height),
                marginBottom:
                    index === numberOfDefinitions - 1
                        ? 0
                        : calcFromHeight(5, height),
                borderBottomColor: 'lightgrey',
                borderBottomWidth:
                    index === numberOfDefinitions - 1
                        ? 0
                        : StyleSheet.hairlineWidth,
            }}
        >
            <Text
                style={{
                    color: colors[theme].txt,
                    fontSize: getFontSize(2, width, height),
                    marginBottom: calcFromHeight(5, height),
                }}
            >
                {definition}
            </Text>
        </View>
    );
};

export default DefinitionItem;
