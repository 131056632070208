import React, { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { AppearanceProvider, useColorScheme } from 'react-native-appearance';

import HomeScreen from '../screens/HomeScreen';
import SettingsScreen from '../screens/SettingsScreen';

import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTheme } from '../redux/settings/settings.action';
import { colors, getFontSize } from '../lib/constants';
import {
    selectTheme,
    selectSystemThemeEnabled,
} from '../redux/settings/settings.selectors';
import SavedScreen from '../screens/SavedScreen';
import { selectToast } from '../redux/temp-state/temp-state.selectors';
import { clearToast } from '../redux/temp-state/temp-state.action';
import { useDimensions } from '@react-native-community/hooks';
import ButtonIcon from './ButtonIcon';

const AppNavigatorWeb: React.FC = () => {
    const theme = useSelector(selectTheme);
    const toast = useSelector(selectToast);

    const dispatch = useDispatch();

    const initialTitle = 'Fast Rhymes';
    const [homeTitle, setHomeTitle] = useState(initialTitle);

    const fadeAnim = useRef(new Animated.Value(1)).current;
    const animationDuration = 250;
    const toastTimeout = 1500;

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: animationDuration,
            useNativeDriver: true,
        }).start();
    }, [fadeAnim, homeTitle]);

    useEffect(() => {
        let handler: undefined | NodeJS.Timeout;

        if (toast.length) {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: animationDuration,
                useNativeDriver: true,
            }).start(() => setHomeTitle(toast));

            handler = setTimeout(() => {
                dispatch(clearToast());
            }, toastTimeout);
        } else if (homeTitle !== initialTitle) {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: animationDuration,
                useNativeDriver: true,
            }).start(() => setHomeTitle(initialTitle));
        }

        return () => {
            if (handler) clearTimeout(handler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toast]);

    // Theme change
    const systemThemeEnabled = useSelector(selectSystemThemeEnabled);
    const deviceTheme = useColorScheme();
    if (
        (deviceTheme === 'light' || deviceTheme === 'dark') &&
        systemThemeEnabled
    ) {
        dispatch(setCurrentTheme(deviceTheme));
    }

    const Stack = createStackNavigator();

    const { width, height } = useDimensions().window;

    return (
        <AppearanceProvider>
            <NavigationContainer>
                <Stack.Navigator mode="card" headerMode="screen">
                    <Stack.Screen
                        name="Home Screen"
                        component={HomeScreen}
                        options={({ navigation }) => ({
                            title: homeTitle,
                            headerTitleAlign: 'center',
                            headerStyle: {
                                backgroundColor: colors[theme].bg,
                                shadowColor: 'transparent',
                                borderBottomWidth: 0,
                            },
                            headerTitleStyle: {
                                marginTop: 30,

                                opacity: fadeAnim,
                                fontWeight: 'normal',
                                fontStyle:
                                    homeTitle === initialTitle
                                        ? undefined
                                        : 'italic',
                                fontSize:
                                    homeTitle === initialTitle
                                        ? getFontSize(3.5, width, height)
                                        : getFontSize(2.25, width, height),
                                fontFamily:
                                    homeTitle === initialTitle
                                        ? 'mustang-regular'
                                        : undefined,
                            },
                            headerTintColor: colors[theme].txt,
                            headerLeftContainerStyle: {
                                position: 'relative',
                            },
                            headerRightContainerStyle: {
                                position: 'relative',
                            },
                            headerLeft:
                                homeTitle === initialTitle
                                    ? () => (
                                          <Animated.View
                                              style={{ opacity: fadeAnim }}
                                          >
                                              <ButtonIcon
                                                  style={{
                                                      marginTop: 10,
                                                      marginLeft: 20,
                                                  }}
                                                  icon="star-outline"
                                                  onPress={() => {
                                                      navigation.navigate(
                                                          'Saved Screen'
                                                      );
                                                  }}
                                              />
                                          </Animated.View>
                                      )
                                    : undefined,
                            headerRight:
                                homeTitle === initialTitle
                                    ? () => (
                                          <Animated.View
                                              style={{ opacity: fadeAnim }}
                                          >
                                              <ButtonIcon
                                                  style={{
                                                      marginTop: 10,
                                                      marginRight: 20,
                                                  }}
                                                  icon="cog-outline"
                                                  onPress={() => {
                                                      navigation.navigate(
                                                          'Settings Screen'
                                                      );
                                                  }}
                                              />
                                          </Animated.View>
                                      )
                                    : undefined,
                        })}
                    />
                    <Stack.Screen
                        name="Settings Screen"
                        component={SettingsScreen}
                        options={{
                            title: 'Settings',
                            headerTitleAlign: 'center',
                            headerStyle: {
                                backgroundColor: colors[theme].bg,
                                shadowColor: 'transparent',
                                borderBottomWidth: 0,
                            },
                            headerTintColor: colors[theme].txt,
                        }}
                    />
                    <Stack.Screen
                        name="Saved Screen"
                        component={SavedScreen}
                        options={{
                            title: 'Saved Words',
                            headerTitleAlign: 'center',
                            headerStyle: {
                                backgroundColor: colors[theme].bg,
                                shadowColor: 'transparent',
                                borderBottomWidth: 0,
                            },
                            headerTintColor: colors[theme].txt,
                        }}
                    />
                </Stack.Navigator>
            </NavigationContainer>
        </AppearanceProvider>
    );
};

export default AppNavigatorWeb;
