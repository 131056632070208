import { createSelector } from 'reselect';
import { LyricType } from '../../lib/types';

interface State {
    lyrics: [] | LyricType[];
}

const selectLyricsState = (state: { lyrics: State }) => state.lyrics;

export const selectLyrics = createSelector(
    [selectLyricsState],
    lyrics => lyrics.lyrics
);
