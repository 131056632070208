import React from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';

import { useDimensions } from '@react-native-community/hooks';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { colors, getFontSize } from '../lib/constants';
import { selectTheme } from '../redux/settings/settings.selectors';
import { LyricType } from '../lib/types';
import TimeAgo from '@andordavoti/react-native-timeago';

interface Props extends LyricType {
    onPress: () => void;
}

const LyricItem: React.FC<Props> = ({
    title,
    content,
    updatedAt,
    createdAt,
    onPress,
}) => {
    const theme = useSelector(selectTheme);

    const { width, height } = useDimensions().window;
    return (
        <TouchableOpacity
            {...{ onPress }}
            style={{
                width,
                justifyContent: 'space-between',
                padding: 10,
                borderColor: 'grey',
                borderBottomWidth: StyleSheet.hairlineWidth,
            }}
        >
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <TextInput
                    editable={false}
                    value={title ? title : 'Untitled Lyrics'}
                    style={{
                        color: colors[theme].txt,
                        fontSize: getFontSize(2, width, height),
                        fontWeight: 'bold',
                        marginBottom: 10,
                        maxWidth: width / 2,
                    }}
                />

                <TimeAgo
                    key={updatedAt}
                    dateTo={new Date(updatedAt)}
                    style={{
                        color: colors[theme].txt,
                        fontWeight: 'bold',
                    }}
                />
            </View>

            <Text
                style={{
                    color: colors[theme].txt,
                    maxHeight: height * 0.1,
                    overflow: 'hidden',
                }}
            >
                {content}
            </Text>
        </TouchableOpacity>
    );
};

export default LyricItem;
