import { combineReducers } from 'redux';

import lyricsReducer from './lyrics/lyrics.reducer';
import savedArticlesReducer from './saved-words/saved-articles.reducer';
import settingsReducer from './settings/settings.reducer';
import tempStateReducer from './temp-state/temp-state.reducer';

export default combineReducers({
    lyrics: lyricsReducer,
    savedArticles: savedArticlesReducer,
    settings: settingsReducer,
    tempState: tempStateReducer,
});
