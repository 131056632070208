import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    StyleSheet,
    ScrollView,
    Linking,
    Alert,
    TouchableOpacity,
} from 'react-native';
import { useSelector } from 'react-redux';
import { ActivityIndicator } from 'react-native-paper';
import * as RNIap from 'react-native-iap';
import { Product, Subscription } from 'react-native-iap';
import { useDimensions } from '@react-native-community/hooks';
import NetInfo from '@react-native-community/netinfo';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { getBottomSpace } from 'react-native-iphone-x-helper';

import {
    calcFromHeight,
    colors,
    getFontSize,
    IS_ANDROID,
    IS_IOS,
    IS_WEB,
} from '../lib/constants';
import { ThemeType } from '../lib/types';
import {
    selectHaptics,
    selectTheme,
} from '../redux/settings/settings.selectors';
import { selectionHaptics } from '../utils/haptics';

const consumablesSkus = [
    'com.andordavoti.donate_once1',
    'com.andordavoti.donate_once5',
    'com.andordavoti.donate_once25',
    'com.andordavoti.donate_once50',
];

const subscriptionSkus = [
    'com.andordavoti.donate_monthly1',
    'com.andordavoti.donate_monthly5',
];

const SupportProjectScreen: React.FC = () => {
    const [connected, setConnected] = useState(false);
    const [consumables, setConsumables] = useState<null | Product[]>(null);
    const [subscriptions, setSubscriptions] = useState<null | Subscription[]>(
        null
    );

    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    useEffect(() => {
        let unsubscribe: any;
        if (!IS_WEB) {
            unsubscribe = NetInfo.addEventListener(state =>
                setConnected(state.isConnected)
            );
        }

        return () => {
            if (!IS_WEB) {
                unsubscribe();
            }
        };
    }, []);

    useEffect(() => {
        const getProducts = async () => {
            await RNIap.initConnection();

            await RNIap.flushFailedPurchasesCachedAsPendingAndroid();

            let cItems = await RNIap.getProducts(consumablesSkus);

            if (IS_IOS) {
                cItems = cItems.filter(item => !item.title.includes('Monthly'));
            }

            setConsumables(cItems);

            const sItems = await RNIap.getSubscriptions(subscriptionSkus);

            setSubscriptions(sItems);
        };

        getProducts();

        const purchaseUpdateSubscription = RNIap.purchaseUpdatedListener(
            async (
                purchase:
                    | RNIap.InAppPurchase
                    | RNIap.SubscriptionPurchase
                    | RNIap.ProductPurchase
            ) => {
                const receipt = purchase.transactionReceipt;

                if (receipt) {
                    // Tell the store that you have delivered what has been paid for.
                    // Failure to do this will result in the purchase being refunded on Android and
                    // the purchase event will reappear on every relaunch of the app until you succeed
                    // in doing the below. It will also be impossible for the user to purchase consumables
                    // again until you do this.
                    if (IS_IOS && purchase.transactionId) {
                        await RNIap.finishTransactionIOS(
                            purchase.transactionId
                        );
                    } else if (IS_ANDROID && purchase.purchaseToken) {
                        // If consumable (can be purchased again)
                        await RNIap.consumePurchaseAndroid(
                            purchase.purchaseToken
                        );
                    }

                    // From react-native-iap@4.1.0 you can simplify above `method`. Try to wrap the statement with `try` and `catch` to also grab the `error` message.
                    // If consumable (can be purchased again)
                    await RNIap.finishTransaction(purchase, true);
                }
            }
        );

        return () => purchaseUpdateSubscription.remove();
    }, []);

    return (
        <View style={styles.container}>
            {consumables && subscriptions && connected ? (
                <ScrollView style={styles.container}>
                    <View style={{ padding: 20 }}>
                        <Text style={styles.text}>
                            If you enjoy using the app, dropping a few coins in
                            the tip jar would be much appriciated!
                        </Text>
                    </View>

                    <View>
                        <Text
                            style={[
                                styles.text,
                                {
                                    fontSize: getFontSize(2.5, width, height),
                                    marginLeft: 20,
                                    marginBottom: 5,
                                    fontWeight: 'bold',
                                },
                            ]}
                        >
                            One time tip:
                        </Text>
                        {consumables
                            .sort((a: any, b: any) => a.price - b.price)
                            .map((consumable, index) => (
                                <TouchableOpacity
                                    key={consumable.productId}
                                    style={{
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                        marginHorizontal: 40,
                                        borderTopColor: 'lightgrey',
                                        borderTopWidth:
                                            index === 0
                                                ? 0
                                                : StyleSheet.hairlineWidth,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                    }}
                                    onPress={() => {
                                        selectionHaptics(hapticsEnabled);

                                        RNIap.requestPurchase(
                                            consumable.productId
                                        );
                                    }}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            style={[
                                                styles.text,
                                                { marginRight: 5 },
                                            ]}
                                        >
                                            {'\u2022'}
                                        </Text>
                                        <Text style={styles.text}>
                                            {consumable.title.replace(
                                                ' (Fast Rhymes)',
                                                ''
                                            )}
                                            {': '}
                                        </Text>
                                    </View>
                                    <Text
                                        style={[
                                            styles.text,
                                            { fontWeight: 'bold' },
                                        ]}
                                    >
                                        {consumable.localizedPrice}
                                    </Text>
                                </TouchableOpacity>
                            ))}
                    </View>

                    <View>
                        <Text
                            style={[
                                styles.text,
                                {
                                    fontSize: getFontSize(2.5, width, height),
                                    marginLeft: 20,
                                    marginBottom: 5,
                                    fontWeight: 'bold',
                                },
                            ]}
                        >
                            Monthly tip:
                        </Text>
                        {subscriptions
                            .sort((a: any, b: any) => a.price - b.price)
                            .map((subscription, index) => (
                                <TouchableOpacity
                                    key={subscription.productId}
                                    style={{
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                        marginHorizontal: 40,
                                        borderTopColor: 'lightgrey',
                                        borderTopWidth:
                                            index === 0
                                                ? 0
                                                : StyleSheet.hairlineWidth,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                    }}
                                    onPress={() => {
                                        selectionHaptics(hapticsEnabled);

                                        RNIap.requestPurchase(
                                            subscription.productId
                                        );
                                    }}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            style={[
                                                styles.text,
                                                { marginRight: 5 },
                                            ]}
                                        >
                                            {'\u2022'}
                                        </Text>
                                        <Text style={styles.text}>
                                            {subscription.title.replace(
                                                ' (Fast Rhymes)',
                                                ''
                                            )}
                                            {': '}
                                        </Text>
                                    </View>
                                    <Text
                                        style={[
                                            styles.text,
                                            { fontWeight: 'bold' },
                                        ]}
                                    >
                                        {subscription.localizedPrice}
                                    </Text>
                                </TouchableOpacity>
                            ))}

                        <TouchableOpacity
                            onPress={() => {
                                selectionHaptics(hapticsEnabled);
                                Linking.openURL(
                                    IS_IOS
                                        ? 'https://apps.apple.com/account/subscriptions'
                                        : 'https://play.google.com/store/account/subscriptions'
                                );
                            }}
                        >
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: colors[theme].link,
                                    fontSize: getFontSize(2.25, width, height),
                                    marginTop: 5,
                                }}
                            >
                                Unsubscribe
                            </Text>
                        </TouchableOpacity>

                        {IS_IOS && (
                            <TouchableOpacity
                                style={{ marginTop: 5 }}
                                onPress={async () => {
                                    selectionHaptics(hapticsEnabled);
                                    await RNIap.getAvailablePurchases();
                                    Alert.alert('Restored purchases');
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: colors[theme].link,
                                        fontSize: getFontSize(
                                            2.25,
                                            width,
                                            height
                                        ),
                                        marginTop: 5,
                                    }}
                                >
                                    Restore purchases
                                </Text>
                            </TouchableOpacity>
                        )}
                    </View>
                </ScrollView>
            ) : (
                <>
                    {connected ? (
                        <View
                            style={[
                                styles.container,
                                {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                            ]}
                        >
                            <ActivityIndicator
                                style={{ margin: calcFromHeight(10, height) }}
                                color={colors[theme].spinner}
                            />
                            <Text
                                style={{
                                    color: colors[theme].txt,
                                    textAlign: 'center',
                                    fontSize: getFontSize(2, width, height),
                                    marginBottom: getBottomSpace(),
                                }}
                            >
                                Loading
                            </Text>
                        </View>
                    ) : (
                        <>
                            <MaterialCommunityIcons
                                name="wifi-strength-alert-outline"
                                size={35}
                                style={styles.icon}
                            />
                            <Text style={styles.nointernet}>
                                Please make sure you are connected to{'\n'}the
                                internet and try again
                            </Text>
                        </>
                    )}
                </>
            )}
        </View>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: colors[theme].bg,
        },
        text: {
            fontSize: getFontSize(2, width, height),
            color: colors[theme].txt,
        },
        nointernet: {
            marginTop: 20,
            color: colors[theme].txt,
            textAlign: 'center',
            fontSize: getFontSize(2, width, height),
            marginBottom: getBottomSpace(),
        },
        icon: {
            marginTop: 30,
            textAlign: 'center',
            color:
                theme === 'dark'
                    ? colors.dark.iconColor
                    : colors.light.iconColor,
        },
    });
};

export default SupportProjectScreen;
