import { LyricType } from '../../lib/types';
import { SAVE_LYRIC, CLEAR_LYRICS, REMOVE_LYRIC } from './lyrics.types';
import { saveLyric, removeLyric } from './lyrics.utils';

interface ActionTypes {
    type: string;
    payload: any;
}

const INITIAL_STATE: {
    lyrics: [] | LyricType[];
} = {
    lyrics: [],
};

const lyricsReducer = (
    state = INITIAL_STATE,
    action: ActionTypes
): typeof INITIAL_STATE => {
    switch (action.type) {
        case SAVE_LYRIC:
            return {
                ...state,
                lyrics: saveLyric(state.lyrics, action.payload),
            };
        case REMOVE_LYRIC:
            return {
                ...state,
                lyrics: removeLyric(state.lyrics, action.payload),
            };
        case CLEAR_LYRICS:
            return {
                ...state,
                lyrics: [],
            };
        default:
            return state;
    }
};

export default lyricsReducer;
