import {
    CLEAR_TOAST,
    RESET_ACTIVE_LYRIC,
    SET_ACTIVE_LYRIC,
    SHOW_TOAST,
} from './temp-state.types';

interface ActionTypes {
    type: string;
    payload: any;
}

const INITIAL_STATE = {
    activeLyric: null,
    toastMessage: '',
};

const tempStateReducer = (state = INITIAL_STATE, action: ActionTypes) => {
    switch (action.type) {
        case SET_ACTIVE_LYRIC:
            return {
                ...state,
                activeLyric: action.payload,
            };
        case RESET_ACTIVE_LYRIC:
            return {
                ...state,
                activeLyric: null,
            };
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload,
            };
        case CLEAR_TOAST:
            return {
                ...state,
                toastMessage: '',
            };
        default:
            return state;
    }
};

export default tempStateReducer;
