import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectOnBoardingDone,
    selectSystemThemeEnabled,
} from '../redux/settings/settings.selectors';
import AppNavigator from './AppNavigator';
import { useColorScheme } from 'react-native-appearance';
import { setCurrentTheme } from '../redux/settings/settings.action';
import OnBoardingScreen from '../screens/OnBoardingScreen';

const AppEntry: React.FC = () => {
    const systemThemeEnabled = useSelector(selectSystemThemeEnabled);

    const dispatch = useDispatch();

    const deviceTheme = useColorScheme();
    if (
        (deviceTheme === 'light' || deviceTheme === 'dark') &&
        systemThemeEnabled
    ) {
        dispatch(setCurrentTheme(deviceTheme));
    }

    const onBoardingDone = useSelector(selectOnBoardingDone);
    if (!onBoardingDone) return <OnBoardingScreen />;
    return <AppNavigator />;
};

export default AppEntry;
