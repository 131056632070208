import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import logger from 'redux-logger';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import rootReducer from './root-reducer';
import { IS_WEB } from '../lib/constants';

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    blacklist: ['tempState'],
};

const middlewares: any[] = [];
if (!IS_WEB) middlewares.push(createSentryMiddleware(Sentry as any));
if (__DEV__) middlewares.push(logger);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
    const store: any = createStore(
        persistedReducer,
        applyMiddleware(...middlewares)
    );
    const persistor = persistStore(store);
    return { store, persistor };
};
