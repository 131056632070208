import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';

import { colors, getFontSize, IS_WEB } from '../lib/constants';
import { selectTheme } from '../redux/settings/settings.selectors';
import { ThemeType } from '../lib/types';
import { useDimensions } from '@react-native-community/hooks';

const Usage: React.FC = () => {
    const theme = useSelector(selectTheme);

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    const tipsMobile = [
        'Choose a search type from the options below the searchbar',
        'Press a word to copy it',
        'Long press a word to see its definitions',
        'Press the star icon to see and manage saved words',
        'Toggle sorting by syllables in the settings',
        'Press the magnifying glass in the searchbar to search rhymezone',
        'Press the flag icon to change the search language',
    ];

    const tipsWeb = [
        'Choose a search type from the options below the searchbar',
        'Press a word to copy it',
        'Long press a word to see its definitions',
        'Press the magnifying glass in the searchbar to search rhymezone',
    ];

    let tips: string[] = [];

    if (IS_WEB) tips = tipsWeb;
    else tips = tipsMobile;

    return (
        <View style={{ maxWidth: 290 }}>
            <Text style={styles.tipsHeader}>Usage:</Text>
            <View style={styles.tipsContainer}>
                {tips.map((tip, index) => (
                    <View
                        key={index}
                        style={[
                            styles.tipContainer,
                            index === tips.length - 1
                                ? { marginBottom: 0 }
                                : null,
                        ]}
                    >
                        <Text style={styles.tipsNumber}>{index + 1}. </Text>
                        <Text style={styles.tipsText}>{tip}</Text>
                    </View>
                ))}
            </View>
        </View>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) => {
    return StyleSheet.create({
        tipsContainer: {
            paddingHorizontal: 20,
        },
        tipContainer: {
            flexDirection: 'row',
            marginBottom: 15,
        },
        tipsText: {
            textAlign: 'left',
            fontSize: getFontSize(1.9, width, height),
            color: colors[theme].txt,
        },
        tipsHeader: {
            textAlign: 'center',
            fontSize: getFontSize(2.5, width, height),
            color: colors[theme].txt,
            marginBottom: 10,
            fontWeight: '600',
        },
        tipsNumber: {
            fontSize: getFontSize(2, width, height),
            fontWeight: 'bold',
            color: colors[theme].txt,
        },
        button: {
            alignSelf: 'center',
            borderColor: colors[theme].borderColorBtn,
        },
    });
};

export default Usage;
