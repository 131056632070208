import React, { useEffect, useState } from 'react';
import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react-native';
import Constants from 'expo-constants';
import * as Analytics from 'expo-firebase-analytics';
import { LogBox } from 'react-native';

import stores from './redux/store';
import { handleError } from './utils/handleError';
import ErrorBoundary from './components/ErrorBoundary';
import AppNavigatorWeb from './components/AppNavigatorWeb';
import AppEntry from './components/AppEntry';
import { IS_WEB } from './lib/constants';

if (!IS_WEB) {
    LogBox.ignoreLogs([
        'VirtualizedLists should never be nested',
        'YellowBox has been replaced with LogBox. Please call LogBox.ignoreLogs() instead.',
    ]);
}

const App: React.FC = () => {
    const [ready, setReady] = useState(false);
    const { store, persistor } = stores();

    useEffect(() => {
        Analytics.setUserId(Constants.installationId);

        if (!IS_WEB) {
            Sentry.init({
                dsn:
                    'https://86d3b44494ce4a07a528431af0cbb6e1@o384994.ingest.sentry.io/5218432',
                debug: __DEV__,
                enableAutoSessionTracking: true,
            });
        }

        const init = async () => {
            try {
                await Font.loadAsync({
                    ...MaterialCommunityIcons.font,
                    'mustang-regular': require('./assets/fonts/mustang.ttf'),
                });
            } catch (err) {
                handleError(err);
            } finally {
                setReady(true);
            }
        };
        init();
    }, []);

    if (!ready) return <AppLoading />;
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {IS_WEB ? <AppNavigatorWeb /> : <AppEntry />}
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
};

export default App;
