import { ArticleType } from '../../lib/types';

export const saveArticle = (
    savedArticles: ArticleType[],
    articleToAdd: ArticleType
) => {
    const wordExists = savedArticles.includes(articleToAdd);

    if (!wordExists) {
        return [...savedArticles, articleToAdd];
    } else {
        return savedArticles;
    }
};

export const removeArticle = (
    savedArticles: ArticleType[],
    articleToRemove: ArticleType
) => {
    const removedWordArray = savedArticles.filter(
        savedArticle => savedArticle.word !== articleToRemove.word
    );
    return removedWordArray;
};
