import React from 'react';
import {
    View,
    TouchableOpacity,
    Text,
    Keyboard,
    StyleSheet,
} from 'react-native';
import Clipboard from 'expo-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { colors, getFontSize } from '../lib/constants';
import {
    selectTheme,
    selectHaptics,
} from '../redux/settings/settings.selectors';
import { ArticleType, ThemeType } from '../lib/types';
import { selectionHaptics, successHaptics } from '../utils/haptics';
import { showToast } from '../redux/temp-state/temp-state.action';
import { useDimensions } from '@react-native-community/hooks';

interface Props {
    article: ArticleType;
    setActiveArticle: React.Dispatch<React.SetStateAction<null | ArticleType>>;
    setArticleModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const RowItem: React.FC<Props> = ({
    article,
    setActiveArticle,
    setArticleModalVisible,
}) => {
    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);

    const { width, height } = useDimensions().window;

    const dispatch = useDispatch();

    const styles = getStyleSheet(theme, width, height);

    const copyToClipboard = () => {
        successHaptics(hapticsEnabled);
        Keyboard.dismiss();
        Clipboard.setString(article.word);
        dispatch(showToast(`copied «${article.word}» to the clipboard`));
    };

    const longPressAction = () => {
        selectionHaptics(hapticsEnabled);

        setArticleModalVisible(true);
        setActiveArticle(article);
    };

    return (
        <View style={styles.containerRowItem}>
            <TouchableOpacity
                onPress={copyToClipboard}
                onLongPress={longPressAction}
            >
                <Text style={styles.word}>{article.word}</Text>
            </TouchableOpacity>
        </View>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) => {
    return StyleSheet.create({
        containerRowItem: {
            justifyContent: 'center',
            borderColor: colors[theme].borderColor,
        },
        word: {
            fontSize: getFontSize(2.5, width, height),
            textAlign: 'center',
            marginTop: 10,
            marginBottom: 10,
            color: colors[theme].txt,
        },
    });
};

export default RowItem;
