import React from 'react';
import { Image, Text } from 'react-native';
import Onboarding from 'react-native-onboarding-swiper';
import {
    calcFromHeight,
    calcFromWidth,
    colors,
    getFontSize,
} from '../lib/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setOnboarding } from '../redux/settings/settings.action';
import { selectTheme } from '../redux/settings/settings.selectors';
import { useDimensions } from '@react-native-community/hooks';

const OnBoardingScreen: React.FC = () => {
    const theme = useSelector(selectTheme);
    const dispatch = useDispatch();

    const { width, height } = useDimensions().window;

    const pages = [
        {
            backgroundColor: colors[theme].bg,
            image: (
                <Image
                    resizeMode="contain"
                    style={{ height: '150%', tintColor: colors[theme].txt }}
                    source={require('../assets/img/onboarding-1.png')}
                />
            ),
            title: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        fontSize: getFontSize(2.5, width, height),
                    }}
                >
                    Welcome to{'  '}
                    <Text
                        style={{
                            color: colors[theme].txt,
                            fontFamily: 'mustang-regular',
                            fontSize: getFontSize(3.25, width, height),
                        }}
                    >
                        Fast Rhymes
                    </Text>
                </Text>
            ),
            subtitle: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        marginTop: calcFromHeight(11, height),
                        margin: calcFromWidth(50, width),
                        fontSize: getFontSize(2, width, height),
                        lineHeight: 25,
                    }}
                >
                    The perfect app to assist you in writing lyrics of any kind.
                    Made for everyone.
                </Text>
            ),
        },
        {
            backgroundColor: colors[theme].bg,
            image: (
                <Image
                    resizeMode="contain"
                    style={{ height: '150%', tintColor: colors[theme].txt }}
                    source={require('../assets/img/onboarding-2.png')}
                />
            ),
            title: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        fontSize: getFontSize(2.5, width, height),
                    }}
                >
                    Search
                </Text>
            ),
            subtitle: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        marginTop: calcFromHeight(11, height),
                        margin: calcFromWidth(50, width),
                        fontSize: getFontSize(2, width, height),
                        lineHeight: 25,
                    }}
                >
                    Search for rhymes, associations and other categories that
                    help you writing.
                </Text>
            ),
        },
        {
            backgroundColor: colors[theme].bg,
            image: (
                <Image
                    resizeMode="contain"
                    style={{ height: '150%', tintColor: colors[theme].txt }}
                    source={require('../assets/img/onboarding-3.png')}
                />
            ),
            title: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        fontSize: getFontSize(2.5, width, height),
                    }}
                >
                    Save Words
                </Text>
            ),
            subtitle: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        marginTop: calcFromHeight(11, height),
                        margin: calcFromWidth(50, width),
                        fontSize: getFontSize(2, width, height),
                        lineHeight: 25,
                    }}
                >
                    Keep the words you look up most often in one place, easily
                    accessible.
                </Text>
            ),
        },
        {
            backgroundColor: colors[theme].bg,
            image: (
                <Image
                    resizeMode="contain"
                    style={{
                        height: '150%',
                        tintColor: colors[theme].txt,
                    }}
                    source={require('../assets/img/onboarding-4.png')}
                />
            ),
            title: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        fontSize: getFontSize(2.5, width, height),
                    }}
                >
                    Find Definitions
                </Text>
            ),
            subtitle: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        marginTop: calcFromHeight(11, height),
                        margin: calcFromWidth(50, width),
                        fontSize: getFontSize(2, width, height),
                        lineHeight: 25,
                    }}
                >
                    Long press any saved word or search result to see it’s
                    definitions.
                </Text>
            ),
        },
        {
            backgroundColor: colors[theme].bg,
            image: (
                <Image
                    resizeMode="contain"
                    style={{
                        height: '150%',
                        tintColor: colors[theme].txt,
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowColor: 'white',
                        shadowOpacity: 1,
                    }}
                    source={require('../assets/img/onboarding-5.png')}
                />
            ),
            title: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        fontSize: getFontSize(2.5, width, height),
                    }}
                >
                    Write Lyrics
                </Text>
            ),
            subtitle: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        marginTop: calcFromHeight(11, height),
                        margin: calcFromWidth(50, width),
                        fontSize: getFontSize(2, width, height),
                        lineHeight: 25,
                    }}
                >
                    Write lyrics in the app. Swipe left on the results to get to
                    the lyrics editor.
                </Text>
            ),
        },
        {
            backgroundColor: colors[theme].bg,
            image: (
                <Image
                    resizeMode="contain"
                    style={{ height: '150%', tintColor: colors[theme].txt }}
                    source={require('../assets/img/onboarding-6.png')}
                />
            ),
            title: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        fontSize: getFontSize(2.5, width, height),
                    }}
                >
                    Leave a Tip
                </Text>
            ),
            subtitle: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        marginTop: calcFromHeight(11, height),
                        margin: calcFromWidth(50, width),
                        fontSize: getFontSize(2, width, height),
                        lineHeight: 25,
                    }}
                >
                    If you enjoy using the app, leaving a tip in the tip jar is
                    much appriciated!
                </Text>
            ),
        },
        {
            backgroundColor: colors[theme].bg,
            image: (
                <Image
                    resizeMode="contain"
                    style={{ height: '150%', tintColor: colors[theme].txt }}
                    source={require('../assets/img/onboarding-7.png')}
                />
            ),
            title: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        fontSize: getFontSize(2.5, width, height),
                    }}
                >
                    Get Started
                </Text>
            ),
            subtitle: (
                <Text
                    style={{
                        color: colors[theme].txt,
                        marginTop: calcFromHeight(11, height),
                        margin: calcFromWidth(50, width),
                        fontSize: getFontSize(2, width, height),
                        lineHeight: 25,
                    }}
                >
                    Press the checkmark icon in the bottom right to get to
                    writing!
                </Text>
            ),
        },
    ];

    return (
        <Onboarding
            pages={pages}
            onSkip={() => dispatch(setOnboarding(true))}
            onDone={() => dispatch(setOnboarding(true))}
            containerStyles={{ flex: 1, justifyContent: 'center' }}
            imageContainerStyles={{
                justifyContent: 'center',
                height: calcFromHeight(150, height),
            }}
        />
    );
};

export default OnBoardingScreen;
