import React from 'react';
import {
    View,
    FlatList,
    Text,
    StyleSheet,
    Keyboard,
    ScrollView,
} from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { useSelector } from 'react-redux';
import { colors, getFontSize, IS_WEB } from '../lib/constants';

import SyllableView from './SyllableView';
import RowItem from './RowItem';
import {
    selectTheme,
    selectSortedBySyllablesEnabled,
} from '../redux/settings/settings.selectors';
import { ArticleTypeBySyllable, ArticleType, ThemeType } from '../lib/types';
import SearchResultPlaceholder from './SearchResultPlaceholder';
import { useDimensions } from '@react-native-community/hooks';

interface Props {
    navigation: any;
    connected: boolean;
    searchTerm: string;
    searchErrorMessage: { title: string; description: string };
    searchEndpoint: string;
    results: ArticleType[];
    sortedResults: ArticleTypeBySyllable[];
    setActiveArticle: React.Dispatch<React.SetStateAction<ArticleType | null>>;
    setArticleModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchResults: React.FC<Props> = ({
    connected,
    navigation,
    searchTerm,
    searchErrorMessage,
    searchEndpoint,
    results,
    sortedResults,
    setActiveArticle,
    setArticleModalVisible,
}) => {
    const theme = useSelector(selectTheme);
    const sortedBySyllablesEnabled = useSelector(
        selectSortedBySyllablesEnabled
    );

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    const renderRow = (article: ArticleType, index: number) => (
        <View
            style={[
                styles.resultsContainer,
                {
                    borderBottomWidth:
                        (results.length % 2 === 0 &&
                            (index === results.length - 1 ||
                                index === results.length - 2)) ||
                        (results.length % 2 !== 0 &&
                            index === results.length - 1)
                            ? StyleSheet.hairlineWidth
                            : 0,
                },
            ]}
        >
            <RowItem
                {...{ article, setActiveArticle, setArticleModalVisible }}
            />
        </View>
    );

    const showResults = () => {
        if (connected || IS_WEB) {
            if (searchTerm === '')
                return <SearchResultPlaceholder {...{ navigation }} />;

            if (
                results.length === 0 &&
                Boolean(searchTerm.length) &&
                searchTerm.includes(' ')
            ) {
                return (
                    <React.Fragment>
                        <Text style={styles.noresults}>
                            Phrases are not supported
                        </Text>
                    </React.Fragment>
                );
            }

            if (Boolean(results.length) && Boolean(searchTerm.length)) {
                if (
                    sortedBySyllablesEnabled &&
                    (searchEndpoint === 'rel_rhy' || searchEndpoint === 'sl') &&
                    !IS_WEB
                ) {
                    return (
                        <ScrollView
                            indicatorStyle={
                                theme === 'dark' ? 'white' : 'black'
                            }
                            style={{ flex: 1 }}
                            onScrollBeginDrag={Keyboard.dismiss}
                            keyboardShouldPersistTaps="always"
                        >
                            {sortedResults.map((item, index: number) => (
                                <SyllableView
                                    key={item.numSyllables}
                                    data={item}
                                    initiallyCollapsed={
                                        index === 0 ? false : true
                                    }
                                    setActiveArticle={setActiveArticle}
                                    setArticleModalVisible={
                                        setArticleModalVisible
                                    }
                                />
                            ))}
                        </ScrollView>
                    );
                } else {
                    return (
                        <FlatList
                            indicatorStyle={
                                theme === 'dark' ? 'white' : 'black'
                            }
                            style={IS_WEB ? { marginBottom: 30 } : null}
                            removeClippedSubviews={false}
                            data={results}
                            keyExtractor={(item, index) =>
                                `${index}${item.word}${item.score}${item.numSyllables}`
                            }
                            renderItem={({ item, index }) =>
                                renderRow(item, index)
                            }
                            numColumns={2}
                            onScrollBeginDrag={Keyboard.dismiss}
                            keyboardShouldPersistTaps="always"
                        />
                    );
                }
            }
            if (
                results.length === 0 &&
                Boolean(searchTerm.length) &&
                !searchTerm.includes(' ')
            ) {
                return (
                    <React.Fragment>
                        <Text style={styles.noresults}>
                            {searchErrorMessage.title}
                        </Text>
                        <Text style={styles.noresultsHelper}>
                            {searchErrorMessage.description}
                        </Text>
                    </React.Fragment>
                );
            }
        }

        if (!connected) {
            return (
                <>
                    <MaterialCommunityIcons
                        name="wifi-strength-alert-outline"
                        size={35}
                        style={styles.icon}
                    />
                    <Text style={styles.nointernet}>
                        Please make sure you are connected to{'\n'}the internet
                        and try again
                    </Text>
                </>
            );
        }
    };

    return <View style={{ flex: 1 }}>{showResults()}</View>;
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) => {
    return StyleSheet.create({
        resultsContainer: {
            flex: 1,
            marginLeft: 20,
            marginRight: 20,
            borderTopWidth: StyleSheet.hairlineWidth,
            borderColor: colors[theme].borderColor,
        },
        noresults: {
            marginTop: 20,
            color: colors[theme].txt,
            textAlign: 'center',
            fontSize: getFontSize(2.5, width, height),
        },
        nointernet: {
            marginTop: 20,
            color: colors[theme].txt,
            textAlign: 'center',
            fontSize: getFontSize(2, width, height),
        },
        noresultsHelper: {
            textAlign: 'center',
            fontSize: getFontSize(1.9, width, height),
            color: colors[theme].txt,
            marginTop: 30,
            marginHorizontal: 40,
            lineHeight: 25,
            marginBottom: 120,
        },
        text: {
            color: colors[theme].txt,
            margin: 20,
            fontSize: getFontSize(2.5, width, height),
            textAlign: 'center',
            fontWeight: '500',
        },
        icon: {
            marginTop: 30,
            textAlign: 'center',
            color: colors[theme].iconColor,
        },
    });
};

export default SearchResults;
