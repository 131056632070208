import { LyricType } from '../../lib/types';
import {
    SET_ACTIVE_LYRIC,
    RESET_ACTIVE_LYRIC,
    SHOW_TOAST,
    CLEAR_TOAST,
} from './temp-state.types';

export const setActiveLyric = (lyric: LyricType) => ({
    type: SET_ACTIVE_LYRIC,
    payload: lyric,
});

export const resetActiveLyric = () => ({
    type: RESET_ACTIVE_LYRIC,
});

export const showToast = (message: string) => ({
    type: SHOW_TOAST,
    payload: message,
});

export const clearToast = () => ({
    type: CLEAR_TOAST,
});
