import { LanguageType } from '../lib/types';

export const getRhymeZoneLink = (
    language: LanguageType,
    searchTerm: string
) => {
    switch (language) {
        case 'english':
            return `https://www.rhymezone.com/r/rhyme.cgi?Word=${searchTerm}&typeofrhyme=perfect&org1=syl&org2=l&org3=y`;
        case 'spanish':
            return `https://rimar.io/r/rhyme.cgi?Word=${searchTerm}&adv_rhy=Rimas&typeofrhyme=adv&lang=es&org1=syl&org2=l&org3=y`;
        default:
            return `https://www.rhymezone.com/r/rhyme.cgi?Word=${searchTerm}&typeofrhyme=perfect&org1=syl&org2=l&org3=y`;
    }
};
