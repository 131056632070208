import * as Haptics from 'expo-haptics';
import { IS_IOS } from '../lib/constants';

export const successHaptics = (hapticsEnabled: boolean) => {
    if (IS_IOS && hapticsEnabled) Haptics.notificationAsync('success' as any);
};

export const errorHaptics = (hapticsEnabled: boolean) => {
    if (IS_IOS && hapticsEnabled) Haptics.notificationAsync('error' as any);
};

export const selectionHaptics = (hapticsEnabled: boolean) => {
    if (IS_IOS && hapticsEnabled) Haptics.selectionAsync();
};
