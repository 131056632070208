import React from 'react';
import {
    StyleProp,
    StyleSheet,
    TouchableOpacity,
    ViewStyle,
} from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { selectTheme } from '../redux/settings/settings.selectors';
import { ThemeType } from '../lib/types';
import { colors } from '../lib/constants';

interface Props {
    icon: 'star-outline' | 'cog-outline' | 'heart-outline';
    onPress: () => void;
    style?: StyleProp<ViewStyle>;
}

const ButtonIcon: React.FC<Props> = ({ icon, onPress, style }) => {
    const theme = useSelector(selectTheme);
    const styles = getStyleSheet(theme);
    return (
        <TouchableOpacity
            style={[styles.iconContainer, style]}
            onPress={onPress}
        >
            <MaterialCommunityIcons name={icon} size={25} style={styles.icon} />
        </TouchableOpacity>
    );
};

const getStyleSheet = (theme: ThemeType) => {
    return StyleSheet.create({
        icon: {
            textAlign: 'center',
            color: colors[theme].iconColor,
        },
        iconContainer: {
            width: 35,
            height: 35,
            borderRadius: 20,
            borderWidth: 2,
            borderColor: colors[theme].borderColorBtn,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
};

export default ButtonIcon;
