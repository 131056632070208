import { LyricType } from '../../lib/types';
import { SAVE_LYRIC, CLEAR_LYRICS, REMOVE_LYRIC } from './lyrics.types';

export const saveLyric = (lyric: LyricType) => ({
    type: SAVE_LYRIC,
    payload: lyric,
});

export const removeLyric = (lyric: LyricType) => ({
    type: REMOVE_LYRIC,
    payload: lyric,
});

export const clearLyrics = () => ({
    type: CLEAR_LYRICS,
});
