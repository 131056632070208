import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
    View,
    TouchableOpacity,
    StyleSheet,
    TextInput,
    Keyboard,
    KeyboardAvoidingView,
    Text,
} from 'react-native';
import Clipboard from 'expo-clipboard';
import syllable from 'syllable';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    colors,
    getFontSize,
    getNumberOfLines,
    IS_ANDROID,
    IS_IOS,
} from '../lib/constants';
import {
    selectHaptics,
    selectTheme,
} from '../redux/settings/settings.selectors';
import {
    errorHaptics,
    selectionHaptics,
    successHaptics,
} from '../utils/haptics';
import { LyricType, ThemeType } from '../lib/types';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { useDimensions } from '@react-native-community/hooks';
import { selectActiveLyric } from '../redux/temp-state/temp-state.selectors';
import {
    resetActiveLyric,
    showToast,
} from '../redux/temp-state/temp-state.action';
import { saveLyric } from '../redux/lyrics/lyrics.action';
import shortid from 'shortid';
import { Portal, Provider } from 'react-native-paper';
import ConfirmationModal from '../components/ConfirmationModal';

interface Props {
    navigation: any;
}

type LyricRefType = Omit<LyricType, 'updatedAt'>;

const LyricEditorScreen: React.FC<Props> = ({ navigation }) => {
    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);
    const activeLyric = useSelector(selectActiveLyric);

    const dispatch = useDispatch();

    const [lyricTitle, setLyricTitle] = useState<string>(
        activeLyric ? activeLyric.title : ''
    );
    const [lyricContent, setLyricContent] = useState<string>(
        activeLyric ? activeLyric.content : ''
    );
    const [keyBoardOpen, setKeyBoardOpen] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(
        false
    );

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    const lyricRef = useRef({
        id: activeLyric?.id || shortid.generate(),
        createdAt: activeLyric?.createdAt || new Date(),
        title: lyricTitle,
        content: lyricContent,
    }) as MutableRefObject<LyricRefType>;

    const contentInputRef = useRef<TextInput>(null);

    useEffect(() => {
        if (IS_IOS) {
            Keyboard.addListener('keyboardWillShow', () =>
                setKeyBoardOpen(true)
            );
            Keyboard.addListener('keyboardWillHide', () =>
                setKeyBoardOpen(false)
            );
        }

        return () => {
            if (
                activeLyric?.title !== lyricRef.current.title ||
                activeLyric?.content !== lyricRef.current.content
            ) {
                dispatch(
                    saveLyric({ ...lyricRef.current, updatedAt: Date.now() })
                );
            }

            dispatch(resetActiveLyric());

            if (IS_IOS) {
                Keyboard.removeListener('keyboardWillShow', () => {});
                Keyboard.removeListener('keyboardWillHide', () => {});
            }
        };
    }, [activeLyric?.title, activeLyric?.content, dispatch]);

    useEffect(() => {
        if (activeLyric) {
            setLyricTitle(activeLyric.title);
            setLyricContent(activeLyric.content);
            lyricRef.current.title = activeLyric.title;
            lyricRef.current.content = activeLyric.content;
        }
    }, [activeLyric]);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (
                activeLyric?.title !== lyricRef.current.title ||
                activeLyric?.content !== lyricRef.current.content
            ) {
                dispatch(
                    saveLyric({ ...lyricRef.current, updatedAt: Date.now() })
                );
            }
        }, 150);

        return () => clearTimeout(handler);
    }, [
        lyricTitle,
        lyricContent,
        activeLyric?.title,
        activeLyric?.content,
        dispatch,
    ]);

    const insertFromClipboard = async () => {
        const clipboardString = await Clipboard.getStringAsync();

        if (clipboardString.length) {
            successHaptics(hapticsEnabled);

            if (lyricContent?.length) {
                setLyricContent(lyricContent + ' ' + clipboardString);
                lyricRef.current.content += ' ' + clipboardString;
            } else {
                setLyricContent(clipboardString);
                lyricRef.current.content = clipboardString;
            }
        } else {
            errorHaptics(hapticsEnabled);
            dispatch(showToast('your clipboard is empty'));
        }
    };

    const copyToClipBoard = () => {
        if (lyricContent?.length) {
            successHaptics(hapticsEnabled);
            dispatch(showToast('lyrics are exported to your clipboard'));
            Clipboard.setString(lyricContent);
        } else {
            errorHaptics(hapticsEnabled);
            dispatch(showToast("can't export an empty lyric"));
        }
    };

    return (
        <Provider>
            <Portal>
                <View
                    style={{
                        flex: 1,
                        backgroundColor: colors[theme].bg,
                    }}
                >
                    <KeyboardAvoidingView
                        style={{
                            flex: 1,
                            backgroundColor: colors[theme].bg,
                            paddingHorizontal: 25,
                            paddingBottom: getBottomSpace() + 50,
                        }}
                        behavior={IS_IOS ? 'padding' : 'height'}
                        keyboardVerticalOffset={
                            IS_ANDROID ? height * 0.35 : height * 0.3
                        }
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginVertical: 25,
                            }}
                        >
                            <TextInput
                                selectionColor="grey"
                                keyboardAppearance={theme}
                                placeholderTextColor="grey"
                                placeholder="Lyric Title"
                                style={styles.lyricTitle}
                                defaultValue={
                                    activeLyric ? activeLyric.title : ''
                                }
                                value={lyricTitle}
                                returnKeyType="next"
                                onSubmitEditing={() =>
                                    contentInputRef.current?.focus()
                                }
                                onChangeText={text => {
                                    lyricRef.current.title = text;
                                    setLyricTitle(text);
                                }}
                            />

                            <TouchableOpacity
                                onPress={() => {
                                    navigation.navigate('Your Lyrics');
                                    selectionHaptics(hapticsEnabled);
                                }}
                            >
                                <MaterialCommunityIcons
                                    style={{
                                        color: colors[theme].txt,
                                    }}
                                    name="close"
                                    size={22}
                                />
                            </TouchableOpacity>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                marginBottom: 25,
                            }}
                        >
                            <TouchableOpacity onPress={insertFromClipboard}>
                                <MaterialCommunityIcons
                                    style={{
                                        color: colors[theme].txt,
                                    }}
                                    name="clipboard-text-outline"
                                    size={22}
                                />
                            </TouchableOpacity>

                            {IS_IOS && (
                                <TouchableOpacity
                                    disabled={!keyBoardOpen}
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    onPress={() => {
                                        Keyboard.dismiss();
                                        selectionHaptics(hapticsEnabled);
                                    }}
                                >
                                    <MaterialCommunityIcons
                                        style={{
                                            margin: 0,
                                            color: !keyBoardOpen
                                                ? 'grey'
                                                : colors[theme].txt,
                                        }}
                                        name="keyboard-outline"
                                        size={22}
                                    />
                                    <MaterialCommunityIcons
                                        style={{
                                            position: 'absolute',
                                            top: 13,
                                            color: !keyBoardOpen
                                                ? 'grey'
                                                : colors[theme].txt,
                                        }}
                                        name="chevron-down"
                                        size={20}
                                    />
                                </TouchableOpacity>
                            )}

                            <TouchableOpacity
                                onPress={() => {
                                    Keyboard.dismiss();
                                    selectionHaptics(hapticsEnabled);
                                    setConfirmationModalVisible(true);
                                }}
                            >
                                <MaterialCommunityIcons
                                    style={{
                                        color: colors[theme].txt,
                                    }}
                                    name="delete-outline"
                                    size={22}
                                />
                            </TouchableOpacity>

                            <TouchableOpacity onPress={copyToClipBoard}>
                                <MaterialCommunityIcons
                                    style={{
                                        color: colors[theme].txt,
                                    }}
                                    name="export"
                                    size={22}
                                />
                            </TouchableOpacity>
                        </View>

                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <View
                                style={{
                                    minWidth: getFontSize(2, width, height),
                                    paddingRight: 10,
                                }}
                            >
                                {lyricContent
                                    .split(/\n/)
                                    .map((sentence, index) => {
                                        const numberOfLinesArr = new Array(
                                            getNumberOfLines(
                                                sentence,
                                                getFontSize(2, width, height)
                                            )
                                        ).fill(0);

                                        const syllables = syllable(sentence);

                                        return (
                                            <View key={index}>
                                                <Text
                                                    style={
                                                        (styles.syllableCount,
                                                        syllables === 0 && {
                                                            color:
                                                                'transparent',
                                                        })
                                                    }
                                                >
                                                    {syllables}
                                                </Text>
                                                {numberOfLinesArr.map(
                                                    (_, idx) => (
                                                        <Text
                                                            key={idx}
                                                            style={[
                                                                styles.syllableCount,
                                                                {
                                                                    color:
                                                                        'transparent',
                                                                },
                                                            ]}
                                                        >
                                                            0
                                                        </Text>
                                                    )
                                                )}
                                            </View>
                                        );
                                    })}
                            </View>

                            <TextInput
                                ref={contentInputRef}
                                selectionColor="grey"
                                keyboardAppearance={theme}
                                placeholder="Your Lyrics..."
                                multiline
                                placeholderTextColor="grey"
                                style={styles.lyricContent}
                                defaultValue={
                                    activeLyric ? activeLyric.content : ''
                                }
                                value={lyricContent}
                                onChangeText={text => {
                                    lyricRef.current.content = text;
                                    setLyricContent(text);
                                }}
                            />
                        </View>
                    </KeyboardAvoidingView>
                </View>

                <ConfirmationModal
                    question="Are you sure you want to delete this lyric?"
                    visible={confirmationModalVisible}
                    dismissAction={() => setConfirmationModalVisible(false)}
                    confirmationButton={{
                        label: 'Delete',
                        color: colors[theme].txt,
                    }}
                    confirmationAction={() => {
                        lyricRef.current = {
                            ...lyricRef.current,
                            title: '',
                            content: '',
                        };

                        navigation.navigate('Your Lyrics');
                    }}
                />
            </Portal>
        </Provider>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) =>
    StyleSheet.create({
        lyricTitle: {
            color: colors[theme].txt,
            fontSize: getFontSize(2.5, width, height),
            fontWeight: 'bold',
            width: width * 0.7,
        },
        lyricContent: {
            color: colors[theme].txt,
            fontSize: getFontSize(2, width, height),
            fontWeight: 'normal',
            paddingTop: 0,
        },
        syllableCount: {
            color: 'grey',
            fontSize: getFontSize(2, width, height),
            fontWeight: 'normal',
            textAlign: 'right',
            lineHeight: getFontSize(2, width, height) * 1.35,
        },
    });

export default LyricEditorScreen;
