import { isIphoneX } from 'react-native-iphone-x-helper';
import { Platform, StatusBar, Dimensions } from 'react-native';

export const { height: STATIC_HEIGHT, width: STATIC_WIDTH } = Dimensions.get(
    'window'
);

export const IS_IOS = Platform.OS === 'ios';
export const IS_ANDROID = Platform.OS === 'android';
export const IS_WEB = Platform.OS === 'web';

export const links = {
    authorWebsite: 'https://andordavoti.com',
    webApp: 'https://fastrhymes.com',
    mailtoAuthor: 'mailto:andor.davoti@gmail.com',
    privacy: 'https://andordavoti.com/privacy/fast_rhymes',
};

// color palette for light mode and dark mode
export const colors = {
    light: {
        bg: 'white',
        sbBg: 'white',
        iconColor: 'black',
        txt: 'black',
        borderColor: 'grey',
        borderColorBtn: 'black',
        spinner: 'black',
        topTabPageIndicator: '#1c1c1c',
        link: 'darkblue',
        fabBg: '#1c1c1c',
    },
    dark: {
        bg: '#1c1c1c',
        sbBg: '#4e4e4e',
        iconColor: 'white',
        txt: 'white',
        borderColor: 'white',
        borderColorBtn: 'white',
        spinner: 'white',
        topTabPageIndicator: 'white',
        link: 'lightblue',
        fabBg: 'white',
    },
};

// make horizontal sizing responsive
export const calcFromWidth = (value: number, deviceWidth: number) =>
    value * (deviceWidth / 375.0);

// make vertical sizing responsive
export const calcFromHeight = (value: number, deviceHeight: number) =>
    value * (deviceHeight / 667.0);

const getDeviceHeight = (deviceWidth: number, deviceHeight: number): number => {
    const standardLength =
        deviceWidth > deviceHeight ? deviceWidth : deviceHeight;

    const offset =
        deviceWidth > deviceHeight
            ? 0
            : Platform.OS === 'ios'
            ? 78
            : StatusBar.currentHeight || 24;

    return isIphoneX() || Platform.OS === 'android'
        ? standardLength - offset
        : standardLength;
};

// make font sizing responsive
// taken from https://github.com/heyman333/react-native-responsive-fontSize/blob/master/index.js (15/01/21)
export const getFontSize = (
    percent: number,
    deviceWidth: number,
    deviceHeight: number
) => {
    const heightPercent =
        (percent * getDeviceHeight(deviceWidth, deviceHeight)) / 100;

    if (deviceHeight < deviceWidth) {
        return Math.round(heightPercent) * (deviceHeight / deviceWidth) * 0.8;
    }
    return Math.round(heightPercent);
};

export const getNumberOfLines = (sentence: string, fontSize: number) => {
    return Math.floor(sentence.length / fontSize / 3.25);
};

// // guideline height for standard 5" device screen is 680
// export function RFValue(fontSize: number, standardScreenHeight = 680, deviceWidth: number, deviceHeight: number) {
//     const heightPercent = (fontSize * getDeviceHeight(deviceWidth, deviceHeight)) / standardScreenHeight;
//     return Math.round(heightPercent);
// }
