import React, { useCallback, useEffect, useState } from 'react';
import {
    View,
    StyleSheet,
    Keyboard,
    Image,
    FlatList,
    TouchableOpacity,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { Searchbar, Button, ActivityIndicator } from 'react-native-paper';
import { useDimensions } from '@react-native-community/hooks';
import { Portal, Provider } from 'react-native-paper';
import { useNetInfo } from '@react-native-community/netinfo';

import SearchResults from '../components/SearchResults';
import { colors, IS_WEB } from '../lib/constants';

import searchTypes from '../lib/searchTypes';
import {
    selectTheme,
    selectHaptics,
    selectLanguage,
    selectMaxResults,
    selectSortedBySyllablesEnabled,
} from '../redux/settings/settings.selectors';
import { ArticleTypeBySyllable, ArticleType, ThemeType } from '../lib/types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from '../utils/handleError';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { openLink } from '../utils/openLink';
import { errorHaptics, selectionHaptics } from '../utils/haptics';
import { showToast } from '../redux/temp-state/temp-state.action';
import LanguageSelectorModal from '../components/LanguageSelectorModal';
import { getLanguageEndpoint } from '../utils/getLanguageEndpoint';
import useDebounce from '../utils/useDebounce';
import ArticleInfoModal from '../components/ArticleInfoModal';
import { categorizeBySyllable } from '../utils/categorizeBySyllable';
import { getFlagImage } from '../utils/getFlagImage';
import { getRhymeZoneLink } from '../utils/getRhymeZoneLink';

interface Props {
    navigation: any;
}

const noResults = {
    title: 'No Results Found',
    description:
        'Try a different word or tap the magnifying glass in the searchbar to search rhymezone',
};

const serverError = {
    title: 'Server Error',
    description:
        'We seem to be having server issues, please try again in a few minutes',
};

const HomeScreen: React.FC<Props> = ({ navigation }) => {
    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);
    const maxResults = useSelector(selectMaxResults);
    const sortedBySyllablesEnabled = useSelector(
        selectSortedBySyllablesEnabled
    );
    const selectedLanguage = useSelector(selectLanguage);

    const dispatch = useDispatch();

    const { width } = useDimensions().window;

    const styles = getStyleSheet(theme, width);

    const [searching, setSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchEndpoint, setSearchEndpoint] = useState('rel_rhy');
    const [results, setResults] = useState<ArticleType[]>([]);
    const [sortedResults, setSortedResults] = useState<ArticleTypeBySyllable[]>(
        []
    );
    const [searchErrorMessage, setSearchErrorMessage] = useState<{
        title: string;
        description: string;
    }>(noResults);

    const debouncedSearchTerm = useDebounce(searchTerm, 225);

    const [activeArticle, setActiveArticle] = useState<null | ArticleType>(
        null
    );
    const [articleModalVisible, setArticleModalVisible] = useState(false);

    const [languageSelectorVisible, setLanguageSelectorVisible] = useState(
        false
    );

    const connected = useNetInfo().isConnected;

    const fetchData = useCallback(async () => {
        try {
            const result = await fetch(
                `https://api.datamuse.com/words?max=${maxResults || 500}&${
                    selectedLanguage === 'english'
                        ? searchEndpoint || 'rel_rhy'
                        : 'sl'
                }=${debouncedSearchTerm}${
                    selectedLanguage === 'english'
                        ? ''
                        : `&v=${getLanguageEndpoint(selectedLanguage)}`
                }&md=d${selectedLanguage === 'spanish' ? '&arhy=1' : ''}`
            );

            setSearching(false);

            const data = await result.json();
            setResults(data);

            if (sortedBySyllablesEnabled && !IS_WEB) {
                setSortedResults(categorizeBySyllable(data));
            }

            if (searchErrorMessage.title !== noResults.title) {
                setSearchErrorMessage(noResults);
            }
        } catch (err) {
            if (
                err?.response?.status === 500 ||
                err?.response?.status === 503 ||
                err?.response?.status === 504
            ) {
                setSearchErrorMessage(serverError);
            } else {
                handleError(err);
            }
        }
    }, [
        debouncedSearchTerm,
        maxResults,
        searchEndpoint,
        searchErrorMessage.title,
        selectedLanguage,
        sortedBySyllablesEnabled,
    ]);

    const searchRhymeZone = async () => {
        if (!searchTerm || searchTerm === '') {
            errorHaptics(hapticsEnabled);
            Keyboard.dismiss();
            dispatch(showToast('enter a search term to search RhymeZone'));
        } else if (searchTerm.includes(' ')) {
            errorHaptics(hapticsEnabled);
            Keyboard.dismiss();
            dispatch(showToast('phrases are currently unsupported'));
        } else {
            try {
                selectionHaptics(hapticsEnabled);
                await openLink(getRhymeZoneLink(selectedLanguage, searchTerm));
            } catch (err) {
                handleError(err);
            }
        }
    };

    useEffect(() => {
        if (connected && searchTerm.length) {
            setSearching(true);
            fetchData();
        }
    }, [
        debouncedSearchTerm,
        selectedLanguage,
        searchEndpoint,
        connected,
        searchTerm.length,
        fetchData,
    ]);

    return (
        <Provider>
            <Portal>
                <View style={styles.container}>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => {
                                selectionHaptics(hapticsEnabled);
                                setLanguageSelectorVisible(true);
                            }}
                        >
                            <Image
                                resizeMode="contain"
                                style={{
                                    height: 30,
                                    width: 30,
                                    margin: 20,
                                    marginRight: 0,
                                }}
                                source={getFlagImage(selectedLanguage)}
                            />
                        </TouchableOpacity>

                        <Searchbar
                            selectionColor="grey"
                            keyboardAppearance={theme}
                            placeholderTextColor={colors[theme].txt}
                            style={styles.searchBar}
                            iconColor={theme === 'dark' ? 'white' : 'gray'}
                            inputStyle={[
                                { color: colors[theme].txt, paddingLeft: 0 },
                                IS_WEB ? ({ outlineWidth: 0 } as any) : null,
                            ]}
                            placeholder="Search"
                            onChangeText={term =>
                                setSearchTerm(term.replace(' ', ''))
                            }
                            value={searchTerm}
                            onIconPress={searchRhymeZone}
                            clearIcon={
                                searching
                                    ? ({ color }) => (
                                          <ActivityIndicator color={color} />
                                      )
                                    : undefined
                            }
                        />
                    </View>

                    {selectedLanguage === 'english' && (
                        <>
                            {IS_WEB && width > 625 ? (
                                <View
                                    style={{
                                        flexWrap: 'wrap',
                                        marginBottom: 20,
                                        flexDirection: 'row',
                                        alignSelf: 'center',
                                        maxWidth: 600,
                                        width,
                                    }}
                                >
                                    {searchTypes.map(searchType => (
                                        <Button
                                            key={searchType.endPoint}
                                            style={[
                                                styles.button,
                                                { marginBottom: 10 },
                                            ]}
                                            color={colors[theme].txt}
                                            mode={
                                                searchEndpoint ===
                                                searchType.endPoint
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            onPress={() => {
                                                selectionHaptics(
                                                    hapticsEnabled
                                                );
                                                setSearchEndpoint(
                                                    searchType.endPoint
                                                );
                                            }}
                                        >
                                            {searchType.type}
                                        </Button>
                                    ))}
                                </View>
                            ) : (
                                <View style={styles.typeContainer}>
                                    <FlatList
                                        nestedScrollEnabled
                                        renderScrollComponent={p => (
                                            <ScrollView
                                                nestedScrollEnabled
                                                {...p}
                                            />
                                        )}
                                        horizontal
                                        showsHorizontalScrollIndicator={false}
                                        keyboardShouldPersistTaps="always"
                                        data={searchTypes}
                                        keyExtractor={({ endPoint }) =>
                                            endPoint
                                        }
                                        renderItem={({ item }) => (
                                            <Button
                                                style={styles.button}
                                                color={colors[theme].txt}
                                                mode={
                                                    searchEndpoint ===
                                                    item.endPoint
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                                onPress={() => {
                                                    selectionHaptics(
                                                        hapticsEnabled
                                                    );
                                                    setSearchEndpoint(
                                                        item.endPoint
                                                    );
                                                }}
                                            >
                                                {item.type}
                                            </Button>
                                        )}
                                    />
                                </View>
                            )}
                        </>
                    )}

                    {IS_WEB ? (
                        <View
                            style={{
                                flex: 1,
                                alignSelf: 'center',
                                maxWidth: 600,
                                width,
                            }}
                        >
                            <SearchResults
                                {...{
                                    connected,
                                    navigation,
                                    searchTerm,
                                    searchErrorMessage,
                                    searchEndpoint,
                                    results,
                                    sortedResults,
                                    setActiveArticle,
                                    setArticleModalVisible,
                                }}
                            />
                        </View>
                    ) : (
                        <SearchResults
                            {...{
                                connected,
                                navigation,
                                searchTerm,
                                searchErrorMessage,
                                searchEndpoint,
                                results,
                                sortedResults,
                                setActiveArticle,
                                setArticleModalVisible,
                            }}
                        />
                    )}
                </View>

                <ArticleInfoModal
                    activeArticle={activeArticle}
                    visible={articleModalVisible}
                    dismissAction={() => {
                        setArticleModalVisible(false);
                        setActiveArticle(null);
                    }}
                />
                <LanguageSelectorModal
                    visible={languageSelectorVisible}
                    dismissAction={() => setLanguageSelectorVisible(false)}
                />
            </Portal>
        </Provider>
    );
};

const getStyleSheet = (theme: ThemeType, width: number) =>
    StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            backgroundColor: colors[theme].bg,
        },
        searchBar: {
            maxWidth: 600,
            width: width - 80,
            alignSelf: 'center',
            borderRadius: 20,
            margin: 20,
            marginLeft: 10,
            backgroundColor: colors[theme].sbBg,
            borderColor: 'black',
            borderWidth: theme === 'dark' ? 0 : StyleSheet.hairlineWidth,
        },
        buttonContainer: {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginBottom: getBottomSpace(),
        },
        button: {
            alignSelf: 'center',
            marginLeft: 10,
            borderColor: colors[theme].borderColorBtn,
        },
        typeContainer: {
            marginBottom: 20,
            marginLeft: 10,
            marginRight: 20,
        },
    });

export default HomeScreen;
