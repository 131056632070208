import { createSelector } from 'reselect';
import { ArticleType } from '../../lib/types';

interface State {
    savedArticles: ArticleType[];
}

const selectSavedArticlesState = (state: { savedArticles: State }) =>
    state.savedArticles;

export const selectSavedArticles = createSelector(
    [selectSavedArticlesState],
    savedArticles => savedArticles.savedArticles
);
