import React, { useEffect } from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    Text,
    Image,
    Keyboard,
} from 'react-native';
import { Modal, Title } from 'react-native-paper';
import {
    colors,
    calcFromWidth,
    calcFromHeight,
    IS_WEB,
    getFontSize,
} from '../lib/constants';
import { LanguageType, ThemeType } from '../lib/types';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectTheme,
    selectHaptics,
    selectLanguage,
} from '../redux/settings/settings.selectors';
import { selectionHaptics, successHaptics } from '../utils/haptics';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useDimensions } from '@react-native-community/hooks';
import { showToast } from '../redux/temp-state/temp-state.action';
import { setLanguage } from '../redux/settings/settings.action';

interface Props {
    visible: boolean;
    dismissAction: () => void;
}

const languages = [
    {
        name: 'English',
        image: require('../assets/img/english-flag.png'),
    },
    {
        name: 'Spanish',
        image: require('../assets/img/spanish-flag.png'),
    },
];

const LanguageSelectorModal: React.FC<Props> = ({ visible, dismissAction }) => {
    const theme: ThemeType = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);
    const selectedLanguage = useSelector(selectLanguage);

    const dispatch = useDispatch();

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    useEffect(() => {
        if (visible) Keyboard.dismiss();
    }, [visible]);

    return (
        <Modal
            contentContainerStyle={styles.modal}
            visible={visible}
            onDismiss={dismissAction}
        >
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Title style={{ color: colors[theme].txt }}>
                        Select search language:
                    </Title>
                </View>
                <TouchableOpacity
                    onPress={() => {
                        selectionHaptics(hapticsEnabled);
                        dismissAction();
                    }}
                >
                    <MaterialCommunityIcons
                        name="close"
                        size={24}
                        color={colors[theme].txt}
                    />
                </TouchableOpacity>
            </View>

            <Text
                style={{
                    color: theme === 'dark' ? 'lightgrey' : 'grey',
                    fontWeight: '500',
                    marginBottom: 20,
                }}
            >
                (Spanish search is currently limited to just rhymes and
                definitions)
            </Text>

            {languages.map(({ name, image }, index) => (
                <TouchableOpacity
                    key={index}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: index !== languages.length - 1 ? 20 : 0,
                    }}
                    onPress={() => {
                        dispatch(
                            setLanguage(name.toLowerCase() as LanguageType)
                        );
                        dispatch(showToast(`search language is now ${name}`));
                        successHaptics(hapticsEnabled);
                        dismissAction();
                    }}
                >
                    <Image
                        resizeMode="contain"
                        style={{
                            height: 30,
                            width: 30,
                        }}
                        source={image}
                    />
                    <Text style={styles.text}>
                        {name}
                        <Text
                            style={[
                                styles.text,
                                {
                                    color:
                                        theme === 'dark' ? 'lightgrey' : 'grey',
                                },
                            ]}
                        >
                            {selectedLanguage === name.toLowerCase()
                                ? ' (selected)'
                                : ''}
                        </Text>
                    </Text>
                </TouchableOpacity>
            ))}
        </Modal>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) =>
    StyleSheet.create({
        modal: {
            backgroundColor: colors[theme].bg,
            marginHorizontal: IS_WEB ? 40 : calcFromWidth(40, width),
            padding: 20,
            borderRadius: 5,
            marginBottom: calcFromHeight(50, height),
            maxHeight: height * 0.6,
            alignSelf: IS_WEB ? 'center' : undefined,
            maxWidth: 600,
            shadowOffset: {
                width: 10,
                height: 10,
            },
            elevation: 10,
        },
        icon: {
            textAlign: 'center',
            color: colors[theme].iconColor,
        },
        text: {
            color: colors[theme].txt,
            fontWeight: '500',
            fontSize: getFontSize(2.25, width, height),
            marginLeft: 5,
        },
    });

export default LanguageSelectorModal;
