import { ArticleType } from '../../lib/types';
import {
    SAVE_ARTICLE,
    REMOVE_ARTICLE,
    CLEAR_SAVED_ARTICLES,
} from './saved-articles.types';

export const saveArticle = (article: ArticleType) => ({
    type: SAVE_ARTICLE,
    payload: article,
});

export const removeArticle = (article: ArticleType) => ({
    type: REMOVE_ARTICLE,
    payload: article,
});

export const clearSavedArticles = () => ({
    type: CLEAR_SAVED_ARTICLES,
});
