import { createSelector } from 'reselect';
import { LyricType } from '../../lib/types';

interface State {
    activeLyric: null | LyricType;
    toastMessage: string;
}

const selectTempState = (state: { tempState: State }) => state.tempState;

export const selectActiveLyric = createSelector(
    [selectTempState],
    tempState => tempState.activeLyric
);

export const selectToast = createSelector(
    [selectTempState],
    tempState => tempState.toastMessage
);
