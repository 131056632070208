import { createSelector } from 'reselect';
import { LanguageType, ThemeType } from '../../lib/types';

interface State {
    theme: ThemeType;
    maxResults: number;
    sortedBySyllablesEnabled: boolean;
    hapticsEnabled: boolean;
    systemThemeEnabled: boolean;
    onBoardingDone: boolean;
    language: LanguageType;
}

const selectSettingsState = (state: { settings: State }) => state.settings;

export const selectSettings = createSelector(
    [selectSettingsState],
    settings => settings
);
export const selectTheme = createSelector(
    [selectSettingsState],
    settings => settings.theme
);
export const selectMaxResults = createSelector(
    [selectSettingsState],
    settings => settings.maxResults
);
export const selectSortedBySyllablesEnabled = createSelector(
    [selectSettingsState],
    settings => settings.sortedBySyllablesEnabled
);
export const selectHaptics = createSelector(
    [selectSettingsState],
    settings => settings.hapticsEnabled
);
export const selectSystemThemeEnabled = createSelector(
    [selectSettingsState],
    settings => settings.systemThemeEnabled
);

export const selectOnBoardingDone = createSelector(
    [selectSettingsState],
    settings => settings.onBoardingDone
);

export const selectLanguage = createSelector(
    [selectSettingsState],
    settings => settings.language
);
