import React, { useEffect, useRef, useState } from 'react';
import { StatusBar, TouchableOpacity, Animated } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import {
    createStackNavigator,
    TransitionPresets,
} from '@react-navigation/stack';
import { AppearanceProvider } from 'react-native-appearance';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Analytics from 'expo-firebase-analytics';
import changeNavigationBarColor from 'react-native-navigation-bar-color';

import HomeScreen from '../screens/HomeScreen';
import SavedScreen from '../screens/SavedScreen';
import SettingsScreen from '../screens/SettingsScreen';

import { useDispatch, useSelector } from 'react-redux';
import {
    colors,
    getFontSize,
    IS_ANDROID,
    IS_IOS,
    STATIC_HEIGHT,
    STATIC_WIDTH,
} from '../lib/constants';
import {
    selectTheme,
    selectHaptics,
    selectLanguage,
} from '../redux/settings/settings.selectors';
import { ThemeType } from '../lib/types';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import LyricsScreen from '../screens/LyricsScreen';
import { selectionHaptics } from '../utils/haptics';
import { selectLyrics } from '../redux/lyrics/lyrics.selectors';
import { clearLyrics } from '../redux/lyrics/lyrics.action';
import LyricEditorScreen from '../screens/LyricEditorScreen';
import { selectToast } from '../redux/temp-state/temp-state.selectors';
import { clearToast } from '../redux/temp-state/temp-state.action';
import SupportProjectScreen from '../screens/SupportProjectScreen';
import { selectSavedArticles } from '../redux/saved-words/saved-articles.selectors';
import { clearSavedArticles } from '../redux/saved-words/saved-articles.action';
import { setLanguage } from '../redux/settings/settings.action';
import ButtonIcon from './ButtonIcon';

const MainStack = createStackNavigator();
const LyricsStack = createStackNavigator();
const TopTab = createMaterialTopTabNavigator();

const LyricsStackScreen: React.FC = () => (
    <LyricsStack.Navigator
        mode={IS_IOS ? 'modal' : 'card'}
        headerMode="none"
        screenOptions={
            IS_IOS
                ? {
                      ...TransitionPresets.ModalPresentationIOS,
                      gestureEnabled: true,
                      cardOverlayEnabled: true,
                      cardShadowEnabled: true,
                      safeAreaInsets: { top: 10 },
                  }
                : undefined
        }
    >
        <LyricsStack.Screen name="Your Lyrics" component={LyricsScreen} />
        <LyricsStack.Screen name="Lyric Editor" component={LyricEditorScreen} />
    </LyricsStack.Navigator>
);

const TabScreen: React.FC = () => {
    const theme: ThemeType = useSelector(selectTheme);

    return (
        <TopTab.Navigator
            tabBarOptions={{
                activeTintColor: colors[theme].txt,
                style: {
                    backgroundColor: colors[theme].bg,
                },
                indicatorStyle: {
                    backgroundColor: colors[theme].topTabPageIndicator,
                },
            }}
        >
            <TopTab.Screen name="Search" component={HomeScreen} />
            <TopTab.Screen name="Your Lyrics" component={LyricsStackScreen} />
        </TopTab.Navigator>
    );
};

const AppNavigator: React.FC = () => {
    const routeNameRef = useRef();
    const navigationRef = useRef<any>();

    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);
    const toast = useSelector(selectToast);

    const dispatch = useDispatch();

    const initialTitle = 'Fast Rhymes';
    const [homeTitle, setHomeTitle] = useState(initialTitle);

    // For migration from v2 to v3
    const lyrics = useSelector(selectLyrics);
    if (lyrics === undefined) {
        dispatch(clearLyrics());
    }
    // End migration

    // For migration from v3.0.5 to v3.1.0
    const savedArticles = useSelector(selectSavedArticles);
    if (savedArticles === undefined || savedArticles === null) {
        dispatch(clearSavedArticles());
    } else {
        if (savedArticles.length !== 0) {
            if (typeof savedArticles[0] === 'string') {
                dispatch(clearSavedArticles());
            }
        }
    }

    const language = useSelector(selectLanguage);
    if (language === undefined || language === null) {
        dispatch(setLanguage('english'));
    }
    // End migration

    const fadeAnim = useRef(new Animated.Value(1)).current;
    const animationDuration = 250;
    const toastTimeout = 1500;

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: animationDuration,
            useNativeDriver: true,
        }).start();
    }, [fadeAnim, homeTitle]);

    useEffect(() => {
        const updateAndroidStatusAndNavBar = async () => {
            StatusBar.setBackgroundColor(colors[theme].bg);
            StatusBar.setBarStyle(
                theme === 'dark' ? 'light-content' : 'dark-content'
            );
            changeNavigationBarColor(
                colors[theme].bg,
                theme === 'dark' ? false : true,
                true
            );
        };

        if (IS_ANDROID) {
            updateAndroidStatusAndNavBar();
        }
    }, [theme]);

    useEffect(() => {
        let handler: undefined | NodeJS.Timeout;

        if (toast.length) {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: animationDuration,
                useNativeDriver: true,
            }).start(() => setHomeTitle(toast));

            handler = setTimeout(() => {
                dispatch(clearToast());
            }, toastTimeout);
        } else if (homeTitle !== initialTitle) {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: animationDuration,
                useNativeDriver: true,
            }).start(() => setHomeTitle(initialTitle));
        }

        return () => {
            if (handler) clearTimeout(handler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toast]);

    return (
        <AppearanceProvider>
            <NavigationContainer
                ref={navigationRef}
                onReady={() =>
                    (routeNameRef.current = navigationRef?.current?.getCurrentRoute().name)
                }
                onStateChange={() => {
                    const previousRouteName = routeNameRef?.current;

                    const currentRouteName = navigationRef?.current?.getCurrentRoute()
                        .name;

                    if (previousRouteName !== currentRouteName) {
                        Analytics.setCurrentScreen(currentRouteName);
                    }

                    // Save the current route name for later comparision
                    routeNameRef.current = currentRouteName;
                }}
            >
                {IS_IOS && (
                    <StatusBar
                        barStyle={
                            theme === 'dark' ? 'light-content' : 'dark-content'
                        }
                    />
                )}
                <MainStack.Navigator
                    mode={IS_IOS ? 'modal' : 'card'}
                    headerMode="screen"
                    screenOptions={
                        IS_IOS
                            ? {
                                  ...TransitionPresets.ModalPresentationIOS,
                                  gestureEnabled: true,
                                  cardOverlayEnabled: true,
                                  cardShadowEnabled: true,
                              }
                            : undefined
                    }
                >
                    <MainStack.Screen
                        name="Home Screen"
                        component={TabScreen}
                        options={({ navigation }) => ({
                            title: homeTitle,
                            headerTitleAlign: 'center',
                            headerStyle: {
                                backgroundColor: colors[theme].bg,
                                shadowColor: 'transparent',
                                elevation: 0,
                                borderBottomWidth: 0,
                            },
                            headerTitleStyle: {
                                opacity: fadeAnim,
                                fontWeight: 'normal',
                                fontStyle:
                                    homeTitle === initialTitle
                                        ? undefined
                                        : 'italic',
                                marginTop: 10,
                                fontSize:
                                    homeTitle === initialTitle
                                        ? getFontSize(
                                              3.5,
                                              STATIC_WIDTH,
                                              STATIC_HEIGHT
                                          )
                                        : getFontSize(
                                              2.25,
                                              STATIC_WIDTH,
                                              STATIC_HEIGHT
                                          ),
                                fontFamily:
                                    homeTitle === initialTitle
                                        ? 'mustang-regular'
                                        : undefined,
                            },
                            headerTintColor: colors[theme].txt,
                            headerLeft:
                                homeTitle === initialTitle
                                    ? () => (
                                          <Animated.View
                                              style={{ opacity: fadeAnim }}
                                          >
                                              <ButtonIcon
                                                  style={{
                                                      marginTop: 10,
                                                      marginLeft: 20,
                                                  }}
                                                  icon="star-outline"
                                                  onPress={() => {
                                                      navigation.navigate(
                                                          'Saved Screen'
                                                      );
                                                      selectionHaptics(
                                                          hapticsEnabled
                                                      );
                                                  }}
                                              />
                                          </Animated.View>
                                      )
                                    : undefined,
                            headerRight:
                                homeTitle === initialTitle
                                    ? () => (
                                          <Animated.View
                                              style={{ opacity: fadeAnim }}
                                          >
                                              <ButtonIcon
                                                  style={{
                                                      marginTop: 10,
                                                      marginRight: 20,
                                                  }}
                                                  icon="cog-outline"
                                                  onPress={() => {
                                                      navigation.navigate(
                                                          'Settings Screen'
                                                      );
                                                      selectionHaptics(
                                                          hapticsEnabled
                                                      );
                                                  }}
                                              />
                                          </Animated.View>
                                      )
                                    : undefined,
                        })}
                    />
                    <MainStack.Screen
                        name="Saved Screen"
                        component={SavedScreen}
                        options={({ navigation }) => ({
                            headerLeft: () => null,
                            headerRight: () => (
                                <TouchableOpacity
                                    onPress={() => {
                                        navigation.pop();
                                        selectionHaptics(hapticsEnabled);
                                    }}
                                >
                                    <MaterialCommunityIcons
                                        style={{
                                            marginRight: 25,
                                            color: colors[theme].txt,
                                        }}
                                        name="close"
                                        size={22}
                                    />
                                </TouchableOpacity>
                            ),
                            title: 'Saved Words',
                            headerTitleAlign: 'center',
                            headerStyle: {
                                backgroundColor: colors[theme].bg,
                                shadowColor: 'transparent',
                                elevation: 0,
                                borderBottomWidth: 0,
                            },
                            headerTintColor: colors[theme].txt,
                        })}
                    />
                    <MainStack.Screen
                        name="Support Project Screen"
                        component={SupportProjectScreen}
                        options={({ navigation }) => ({
                            headerLeft: () => null,
                            headerRight: () => (
                                <TouchableOpacity
                                    onPress={() => {
                                        navigation.pop();
                                        selectionHaptics(hapticsEnabled);
                                    }}
                                >
                                    <MaterialCommunityIcons
                                        style={{
                                            marginRight: 25,
                                            color: colors[theme].txt,
                                        }}
                                        name="close"
                                        size={22}
                                    />
                                </TouchableOpacity>
                            ),
                            title: 'Tip Jar',
                            headerTitleAlign: 'center',
                            headerStyle: {
                                backgroundColor: colors[theme].bg,
                                shadowColor: 'transparent',
                                elevation: 0,
                                borderBottomWidth: 0,
                            },
                            headerTintColor: colors[theme].txt,
                        })}
                    />
                    <MainStack.Screen
                        name="Settings Screen"
                        component={SettingsScreen}
                        options={({ navigation }) => ({
                            headerLeft: () => null,
                            headerRight: () => (
                                <TouchableOpacity
                                    onPress={() => {
                                        navigation.pop();
                                        selectionHaptics(hapticsEnabled);
                                    }}
                                >
                                    <MaterialCommunityIcons
                                        style={{
                                            marginRight: 25,
                                            color: colors[theme].txt,
                                        }}
                                        name="close"
                                        size={22}
                                    />
                                </TouchableOpacity>
                            ),
                            title: 'Settings',
                            headerTitleAlign: 'center',
                            headerStyle: {
                                backgroundColor: colors[theme].bg,
                                shadowColor: 'transparent',
                                elevation: 0,
                                borderBottomWidth: 0,
                            },
                            headerTintColor: colors[theme].txt,
                        })}
                    />
                </MainStack.Navigator>
            </NavigationContainer>
        </AppearanceProvider>
    );
};

export default AppNavigator;
