import { ArticleType, ArticleTypeBySyllable } from '../lib/types';

type BySyllableObj = {
    [key: number]: {
        numSyllables: number;
        result_list: ArticleType[];
    };
};

export const categorizeBySyllable = (
    originalData: ArticleType[]
): ArticleTypeBySyllable[] => {
    const newArr: BySyllableObj = {};
    for (const { numSyllables, word, score, defs } of originalData) {
        const prevValues = newArr[numSyllables];

        const prevResults = prevValues ? prevValues.result_list : [];
        newArr[numSyllables] = {
            numSyllables: numSyllables,
            result_list: [...prevResults, { numSyllables, word, score, defs }],
        };
    }
    return Object.values(newArr);
};
