import { LanguageType } from '../lib/types';

export const getLanguageEndpoint = (language: LanguageType) => {
    switch (language) {
        case 'english':
            return 'en';
        case 'spanish':
            return 'es';
        default:
            return 'en';
    }
};
