import React, { useState } from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Button, Portal, Provider } from 'react-native-paper';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { useDimensions } from '@react-native-community/hooks';

import { useSelector, useDispatch } from 'react-redux';
import { clearSavedArticles } from '../redux/saved-words/saved-articles.action';

import { colors, getFontSize, IS_WEB } from '../lib/constants';
import RowItem from '../components/RowItem';
import {
    selectTheme,
    selectHaptics,
} from '../redux/settings/settings.selectors';
import { selectSavedArticles } from '../redux/saved-words/saved-articles.selectors';
import { ArticleType, ThemeType } from '../lib/types';
import { selectionHaptics } from '../utils/haptics';
import ArticleInfoModal from '../components/ArticleInfoModal';
import ConfirmationModal from '../components/ConfirmationModal';

const SavedScreen: React.FC = () => {
    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);
    const savedArticles = useSelector(selectSavedArticles);

    const dispatch = useDispatch();

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    const [activeArticle, setActiveArticle] = useState<null | ArticleType>(
        null
    );

    const [wordModalVisible, setArticleModalVisible] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(
        false
    );

    const renderRow = (article: ArticleType, index: number) => {
        return (
            <View
                style={[
                    styles.resultContainer,
                    index === 0 || index === 1
                        ? { borderTopWidth: StyleSheet.hairlineWidth }
                        : null,
                ]}
            >
                <RowItem
                    {...{ article, setActiveArticle, setArticleModalVisible }}
                />
            </View>
        );
    };

    return (
        <Provider>
            <Portal>
                <View style={styles.container}>
                    {savedArticles.length ? (
                        <>
                            <FlatList
                                indicatorStyle={
                                    theme === 'dark' ? 'white' : 'black'
                                }
                                style={{
                                    flex: 1,
                                    width,
                                    maxWidth: 600,
                                    marginTop: 40,
                                }}
                                removeClippedSubviews={false}
                                data={savedArticles}
                                keyExtractor={(word, index) =>
                                    `${index}${word}`
                                }
                                renderItem={({ item, index }) =>
                                    renderRow(item, index)
                                }
                                numColumns={2}
                            />
                            <Button
                                style={styles.button}
                                icon="delete"
                                color={colors[theme].txt}
                                mode="outlined"
                                onPress={() => {
                                    selectionHaptics(hapticsEnabled);
                                    setConfirmationModalVisible(true);
                                }}
                            >
                                Clear All
                            </Button>
                        </>
                    ) : (
                        <>
                            <MaterialCommunityIcons
                                name={'information-outline'}
                                size={40}
                                style={styles.icon}
                            />
                            <Text
                                style={[
                                    styles.text,
                                    { marginBottom: getBottomSpace() },
                                ]}
                            >
                                Your saved words {'\n'} will appear here
                            </Text>
                        </>
                    )}
                </View>

                <ArticleInfoModal
                    activeArticle={activeArticle}
                    visible={wordModalVisible}
                    dismissAction={() => {
                        setArticleModalVisible(false);
                        setActiveArticle(null);
                    }}
                />
                <ConfirmationModal
                    visible={confirmationModalVisible}
                    question={`Do you want to clear ${'\n'} all saved words?`}
                    confirmationButton={{
                        label: 'Clear',
                        color: colors[theme].txt,
                    }}
                    confirmationAction={() => {
                        dispatch(clearSavedArticles());
                        setConfirmationModalVisible(false);
                    }}
                    dismissAction={() => {
                        setConfirmationModalVisible(false);
                    }}
                />
            </Portal>
        </Provider>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            backgroundColor: colors[theme].bg,
            alignItems: 'center',
        },
        resultContainer: {
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderColor: colors[theme].borderColor,
        },
        button: {
            alignSelf: 'center',
            marginBottom: IS_WEB ? 30 : getBottomSpace() + 10,
            marginTop: 30,
            margin: 20,
            borderColor: colors[theme].borderColorBtn,
        },
        icon: {
            textAlign: 'center',
            color:
                theme === 'dark'
                    ? colors.dark.iconColor
                    : colors.light.iconColor,
        },
        text: {
            textAlign: 'center',
            color: colors[theme].txt,
            fontSize: getFontSize(2.25, width, height),
            margin: 5,
            lineHeight: 25,
        },
    });
};

export default SavedScreen;
