import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ScrollView,
} from 'react-native';
import { Switch, Button, ToggleButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import Constants from 'expo-constants';
import * as StoreReview from 'expo-store-review';
import * as Linking from 'expo-linking';

import {
    calcFromHeight,
    colors,
    getFontSize,
    IS_IOS,
    IS_WEB,
    links,
} from '../lib/constants';
import {
    setCurrentTheme,
    useSystemTheme,
    useHaptics,
    setMaxResults,
    sortBySyllables,
} from '../redux/settings/settings.action';
import {
    selectTheme,
    selectHaptics,
    selectSystemThemeEnabled,
    selectMaxResults,
    selectSortedBySyllablesEnabled,
} from '../redux/settings/settings.selectors';
import { ThemeType } from '../lib/types';
import { openLink } from '../utils/openLink';
import { selectionHaptics } from '../utils/haptics';
import { useDimensions } from '@react-native-community/hooks';

const SettingsScreen: React.FC = () => {
    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);
    const sortedBySyllablesEnabled = useSelector(
        selectSortedBySyllablesEnabled
    );
    const maxResults = useSelector(selectMaxResults);
    const systemThemeEnabled = useSelector(selectSystemThemeEnabled);

    const dispatch = useDispatch();

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    const [selectedTheme, setSelectedTheme] = useState('system');

    useEffect(() => {
        if (systemThemeEnabled) setSelectedTheme('system');
        else setSelectedTheme(theme);
    }, []);

    const handleThemeChange = (value: string) => {
        if (value) {
            selectionHaptics(hapticsEnabled);
            if (value === 'system') {
                dispatch(useSystemTheme(true));
                setSelectedTheme('system');
            }
            if (value === 'light' || value === 'dark') {
                setSelectedTheme(value);
                dispatch(setCurrentTheme(value));
                dispatch(useSystemTheme(false));
            }
        }
    };

    const handleMaxResultsChange = (value: string) => {
        if (value) {
            selectionHaptics(hapticsEnabled);
            dispatch(setMaxResults(parseInt(value)));
        }
    };

    return (
        <View style={styles.container}>
            <ScrollView contentContainerStyle={styles.container}>
                <View>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingBottom: 10,
                        }}
                    >
                        <Text style={styles.text}>Theme:</Text>

                        <ToggleButton.Row
                            style={styles.toTheRight}
                            onValueChange={handleThemeChange}
                            value={selectedTheme}
                        >
                            <ToggleButton
                                color={
                                    selectedTheme === 'system'
                                        ? 'black'
                                        : 'white'
                                }
                                style={
                                    selectedTheme === 'system'
                                        ? styles.buttonGroupSelected
                                        : styles.buttonGroup
                                }
                                icon="theme-light-dark"
                                value="system"
                            />
                            <ToggleButton
                                color={
                                    selectedTheme === 'light'
                                        ? 'black'
                                        : 'white'
                                }
                                style={
                                    selectedTheme === 'light'
                                        ? styles.buttonGroupSelected
                                        : styles.buttonGroup
                                }
                                icon="weather-sunny"
                                value="light"
                            />
                            <ToggleButton
                                color={
                                    selectedTheme === 'dark' ? 'black' : 'white'
                                }
                                style={
                                    selectedTheme === 'dark'
                                        ? styles.buttonGroupSelected
                                        : styles.buttonGroup
                                }
                                icon="weather-night"
                                value="dark"
                            />
                        </ToggleButton.Row>
                    </View>

                    <View
                        style={{
                            borderTopColor: 'lightgrey',
                            borderTopWidth: StyleSheet.hairlineWidth,
                            paddingTop: 10,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingBottom: 10,
                        }}
                    >
                        <Text style={styles.text}>Max Results:</Text>

                        <ToggleButton.Row
                            style={styles.toTheRight}
                            onValueChange={handleMaxResultsChange}
                            value={maxResults.toString()}
                        >
                            <ToggleButton
                                color={maxResults === 100 ? 'black' : 'white'}
                                style={
                                    maxResults === 100
                                        ? styles.buttonGroupSelected
                                        : styles.buttonGroup
                                }
                                icon={({ size, color }) => (
                                    <Text style={{ color }}>100</Text>
                                )}
                                value="100"
                            />
                            <ToggleButton
                                color={maxResults === 500 ? 'black' : 'white'}
                                style={
                                    maxResults === 500
                                        ? styles.buttonGroupSelected
                                        : styles.buttonGroup
                                }
                                icon={({ size, color }) => (
                                    <Text style={{ color }}>500</Text>
                                )}
                                value="500"
                            />
                            <ToggleButton
                                color={maxResults === 1000 ? 'black' : 'white'}
                                style={
                                    maxResults === 1000
                                        ? styles.buttonGroupSelected
                                        : styles.buttonGroup
                                }
                                icon={({ size, color }) => (
                                    <Text style={{ color }}>1000</Text>
                                )}
                                value="1000"
                            />
                        </ToggleButton.Row>
                    </View>

                    {IS_IOS ? (
                        <View
                            style={{
                                borderTopColor: 'lightgrey',
                                borderTopWidth: StyleSheet.hairlineWidth,
                                paddingTop: 10,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: 10,
                            }}
                        >
                            <Text style={styles.text}>Haptics:</Text>
                            <Switch
                                style={styles.toTheRight}
                                color="grey"
                                value={hapticsEnabled}
                                onValueChange={() =>
                                    dispatch(useHaptics(!hapticsEnabled))
                                }
                            />
                        </View>
                    ) : null}

                    {IS_WEB ? null : (
                        <View
                            style={{
                                borderTopColor: 'lightgrey',
                                borderTopWidth: StyleSheet.hairlineWidth,
                                paddingTop: 10,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Text style={styles.text}>
                                Results by syllable:
                            </Text>
                            <Switch
                                style={styles.toTheRight}
                                color="grey"
                                value={sortedBySyllablesEnabled}
                                onValueChange={() =>
                                    dispatch(
                                        sortBySyllables(
                                            !sortedBySyllablesEnabled
                                        )
                                    )
                                }
                            />
                        </View>
                    )}
                </View>

                <View style={{ marginBottom: getBottomSpace() }}>
                    <Text style={styles.header}>About the App:</Text>

                    {!IS_WEB && (
                        <>
                            <Text
                                style={[
                                    styles.textAuthor,
                                    { fontStyle: 'normal' },
                                ]}
                            >
                                Try the Web App:
                            </Text>

                            <TouchableOpacity
                                onPress={async () =>
                                    await openLink(links.webApp)
                                }
                            >
                                <Text
                                    style={[
                                        styles.textAuthor,
                                        {
                                            fontStyle: 'normal',
                                            margin: 0,
                                            marginBottom: 10,
                                            color: colors[theme].link,
                                        },
                                    ]}
                                >
                                    www.fastrhymes.com
                                </Text>
                            </TouchableOpacity>
                        </>
                    )}

                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        {!IS_WEB ? (
                            <Button
                                style={styles.button}
                                color={colors[theme].txt}
                                mode="outlined"
                                onPress={() => {
                                    selectionHaptics(hapticsEnabled);
                                    StoreReview.requestReview();
                                }}
                            >
                                Rate App
                            </Button>
                        ) : null}

                        <Button
                            style={styles.button}
                            color={colors[theme].txt}
                            mode="outlined"
                            onPress={() => {
                                selectionHaptics(hapticsEnabled);
                                Linking.openURL(links.mailtoAuthor);
                            }}
                        >
                            Contact me
                        </Button>
                    </View>

                    <Text style={styles.textAuthor}>
                        Developed by Andor Davoti
                    </Text>

                    <TouchableOpacity
                        onPress={async () =>
                            await openLink(links.authorWebsite)
                        }
                    >
                        <Text
                            style={[
                                styles.textAuthor,
                                {
                                    fontStyle: 'normal',
                                    margin: 0,
                                    marginBottom: 10,
                                    color: colors[theme].link,
                                },
                            ]}
                        >
                            www.andordavoti.com
                        </Text>
                    </TouchableOpacity>

                    <Text style={styles.textVersion}>
                        Version:{' '}
                        {IS_WEB
                            ? Constants.manifest.version
                            : Constants.nativeAppVersion}
                    </Text>

                    <TouchableOpacity
                        onPress={async () => await openLink(links.privacy)}
                    >
                        <Text
                            style={[
                                styles.textAuthor,
                                {
                                    fontStyle: 'normal',
                                    margin: 0,
                                    marginBottom: 0,
                                    color: colors[theme].link,
                                },
                            ]}
                        >
                            Terms of Service & Privacy policy
                        </Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </View>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors[theme].bg,
        },
        text: {
            textAlign: 'center',
            fontSize: getFontSize(2.5, width, height),
            color: colors[theme].txt,
            margin: 10,
        },
        textAuthor: {
            textAlign: 'center',
            fontSize: getFontSize(2.5, width, height),
            color: colors[theme].txt,
            margin: 10,
            marginBottom: 5,
            fontStyle: 'italic',
        },
        textVersion: {
            textAlign: 'center',
            fontSize: getFontSize(2.5, width, height),
            color: colors[theme].txt,
            margin: 10,
            fontWeight: 'bold',
        },
        header: {
            textAlign: 'center',
            fontSize: getFontSize(3, width, height),
            color: colors[theme].txt,
            fontWeight: '600',
            marginTop: calcFromHeight(20, height),
        },
        button: {
            alignSelf: 'center',
            margin: 15,
            borderColor: colors[theme].borderColorBtn,
        },
        rowData: {
            minHeight: 30,
            marginLeft: 25,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        toTheRight: {
            marginLeft: 10,
        },
        buttonGroup: {
            borderWidth: theme === 'light' ? StyleSheet.hairlineWidth : 0,
            borderColor: 'grey',
            backgroundColor: 'black',
        },
        buttonGroupSelected: {
            borderWidth: theme === 'light' ? StyleSheet.hairlineWidth : 0,
            borderColor: 'grey',
            backgroundColor: 'white',
        },
    });
};

export default SettingsScreen;
