import React from 'react';
import {
    View,
    Text,
    Keyboard,
    ScrollView,
    TouchableOpacity,
    Image,
    StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import { colors, getFontSize, IS_WEB } from '../lib/constants';
import { openLink } from '../utils/openLink';
import { ThemeType } from '../lib/types';
import {
    selectHaptics,
    selectTheme,
} from '../redux/settings/settings.selectors';
import Usage from './Usage';
import { useDimensions } from '@react-native-community/hooks';
import { Button } from 'react-native-paper';
import { selectionHaptics } from '../utils/haptics';

interface Props {
    navigation: any;
}

const SearchResultPlaceholder: React.FC<Props> = ({ navigation }) => {
    const theme = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    return (
        <ScrollView
            onScrollBeginDrag={Keyboard.dismiss}
            contentContainerStyle={{ flexGrow: 1 }}
        >
            <View
                style={[
                    {
                        flex: 1,
                        alignItems: 'center',
                    },
                    IS_WEB && {
                        justifyContent: 'space-around',
                    },
                ]}
            >
                <Usage />

                <View>
                    <Text style={[styles.donateHeader, { marginTop: 20 }]}>
                        Enjoy using the app?
                    </Text>
                    <Button
                        style={styles.button}
                        color={colors[theme].txt}
                        mode="outlined"
                        onPress={() => {
                            selectionHaptics(hapticsEnabled);

                            if (!IS_WEB) {
                                navigation.navigate('Support Project Screen');
                            } else {
                                openLink('https://paypal.me/andordavoti');
                            }
                        }}
                    >
                        Leave a tip ♥️
                    </Button>
                </View>

                {IS_WEB && (
                    <View>
                        <Text style={styles.downloadAppText}>
                            Download the app:
                        </Text>
                        <View style={styles.storeButtonContainer}>
                            <TouchableOpacity
                                onPress={async () =>
                                    await openLink(
                                        'https://apps.apple.com/us/app/fast-rhymes/id1492575811?ls=1'
                                    )
                                }
                            >
                                <Image
                                    resizeMode="contain"
                                    style={styles.storeIcon}
                                    source={require('../assets/app-store-badge.png')}
                                />
                            </TouchableOpacity>

                            <TouchableOpacity
                                onPress={async () =>
                                    await openLink(
                                        'https://play.google.com/store/apps/details?id=rhyme.dictionary.mobileapp'
                                    )
                                }
                            >
                                <Image
                                    resizeMode="contain"
                                    style={styles.storeIcon}
                                    source={require('../assets/google-play-badge.png')}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) => {
    return StyleSheet.create({
        downloadAppText: {
            color: colors[theme].txt,
            fontSize: getFontSize(2.5, width, height),
            textAlign: 'center',
            fontWeight: '500',
            marginBottom: 10,
        },
        storeButtonContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        storeIcon: {
            height: 60,
            width: 200,
            margin: 10,
        },
        donateHeader: {
            textAlign: 'center',
            fontSize: getFontSize(2.5, width, height),
            color: colors[theme].txt,
            marginBottom: 10,
            fontWeight: '600',
        },
        button: {
            alignSelf: 'center',
            borderColor: colors[theme].borderColorBtn,
        },
    });
};

export default SearchResultPlaceholder;
