import React, { useEffect } from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    Text,
    FlatList,
    Keyboard,
} from 'react-native';
import { Modal, Title } from 'react-native-paper';
import {
    colors,
    calcFromWidth,
    calcFromHeight,
    IS_WEB,
    getFontSize,
} from '../lib/constants';
import { ArticleType, ThemeType } from '../lib/types';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectTheme,
    selectHaptics,
} from '../redux/settings/settings.selectors';
import { selectionHaptics, successHaptics } from '../utils/haptics';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useDimensions } from '@react-native-community/hooks';
import { selectSavedArticles } from '../redux/saved-words/saved-articles.selectors';
import {
    removeArticle,
    saveArticle,
} from '../redux/saved-words/saved-articles.action';
import DefinitionItem from './DefinitionItem';
import { showToast } from '../redux/temp-state/temp-state.action';

interface Props {
    activeArticle: null | ArticleType;
    visible: boolean;
    dismissAction: () => void;
}

const ArticleInfoModal: React.FC<Props> = ({
    activeArticle,
    visible,
    dismissAction,
}) => {
    const theme: ThemeType = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);
    const savedArticles = useSelector(selectSavedArticles);

    const dispatch = useDispatch();

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    const isSaved = activeArticle
        ? savedArticles.some(
              savedArticle => savedArticle.word === activeArticle.word
          )
        : false;

    useEffect(() => {
        if (visible) Keyboard.dismiss();
    }, [visible]);

    const renderDefinitions = () => {
        if (activeArticle) {
            if (activeArticle?.defs) {
                if (activeArticle?.defs.length) {
                    return (
                        <FlatList
                            indicatorStyle={
                                theme === 'dark' ? 'white' : 'black'
                            }
                            style={IS_WEB ? { marginBottom: 30 } : null}
                            removeClippedSubviews={false}
                            data={activeArticle.defs}
                            keyExtractor={(item, index) => `${index}${item}`}
                            renderItem={({ item, index }) => (
                                <DefinitionItem
                                    index={index}
                                    numberOfDefinitions={
                                        activeArticle?.defs?.length as any
                                    }
                                    definition={item}
                                />
                            )}
                        />
                    );
                }
            } else {
                return (
                    <Text
                        style={{
                            color: colors[theme].txt,
                            marginBottom: calcFromHeight(10, height),
                            textAlign: 'center',
                            fontSize: getFontSize(2, width, height),
                        }}
                    >
                        No definitions found.
                    </Text>
                );
            }
        }
    };

    return (
        <Modal
            contentContainerStyle={styles.modal}
            visible={visible}
            onDismiss={dismissAction}
        >
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 20,
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <TouchableOpacity
                        onPress={() => {
                            if (activeArticle) {
                                if (isSaved) {
                                    dispatch(removeArticle(activeArticle));
                                    dispatch(
                                        showToast(
                                            `removed «${activeArticle?.word}» from saved words`
                                        )
                                    );
                                    selectionHaptics(hapticsEnabled);
                                } else {
                                    dispatch(saveArticle(activeArticle));
                                    dispatch(
                                        showToast(
                                            `added «${activeArticle?.word}» to saved words`
                                        )
                                    );
                                    successHaptics(hapticsEnabled);
                                }
                            }
                        }}
                    >
                        <MaterialCommunityIcons
                            name={isSaved ? 'star' : 'star-outline'}
                            size={24}
                            color={colors[theme].txt}
                        />
                    </TouchableOpacity>
                    <Title style={{ color: colors[theme].txt, marginLeft: 5 }}>
                        {activeArticle?.word}
                    </Title>
                </View>
                <TouchableOpacity
                    onPress={() => {
                        selectionHaptics(hapticsEnabled);
                        dismissAction();
                    }}
                >
                    <MaterialCommunityIcons
                        name="close"
                        size={24}
                        color={colors[theme].txt}
                    />
                </TouchableOpacity>
            </View>

            <Text
                style={{
                    color: colors[theme].txt,
                    fontWeight: '500',
                    fontSize: getFontSize(2.25, width, height),
                    marginBottom: 20,
                }}
            >
                Definitions:
            </Text>

            {renderDefinitions()}
        </Modal>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) =>
    StyleSheet.create({
        modal: {
            backgroundColor: colors[theme].bg,
            marginHorizontal: IS_WEB ? 40 : calcFromWidth(40, width),
            padding: 20,
            borderRadius: 5,
            marginBottom: calcFromHeight(50, height),
            maxHeight: height * 0.6,
            alignSelf: IS_WEB ? 'center' : undefined,
            maxWidth: 600,
            shadowOffset: {
                width: 10,
                height: 10,
            },
            elevation: 10,
        },
        icon: {
            textAlign: 'center',
            color: colors[theme].iconColor,
        },
    });

export default ArticleInfoModal;
