import { LanguageType } from '../lib/types';

export const getFlagImage = (language: LanguageType) => {
    switch (language) {
        case 'english':
            return require('../assets/img/english-flag.png');
        case 'spanish':
            return require('../assets/img/spanish-flag.png');
        default:
            return require('../assets/img/english-flag.png');
    }
};
