import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Modal, Button } from 'react-native-paper';
import {
    colors,
    calcFromWidth,
    calcFromHeight,
    getFontSize,
} from '../lib/constants';
import { ThemeType } from '../lib/types';
import { useSelector } from 'react-redux';
import {
    selectTheme,
    selectHaptics,
} from '../redux/settings/settings.selectors';
import { selectionHaptics, successHaptics } from '../utils/haptics';
import { useDimensions } from '@react-native-community/hooks';

interface Props {
    question: string;
    visible: boolean;
    dismissAction: () => void;
    confirmationAction: () => void;
    confirmationButton: {
        color: string;
        label: string;
    };
    cancelText?: string;
}

const ConfirmationModal: React.FC<Props> = ({
    question,
    visible,
    confirmationAction,
    dismissAction,
    confirmationButton,
    cancelText = 'Cancel',
}) => {
    const theme: ThemeType = useSelector(selectTheme);
    const hapticsEnabled = useSelector(selectHaptics);

    const { width, height } = useDimensions().window;

    const styles = getStyleSheet(theme, width, height);

    return (
        <Modal
            contentContainerStyle={styles.modal}
            visible={visible}
            onDismiss={dismissAction}
        >
            <Text style={styles.text}>{question}</Text>

            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    marginBottom: calcFromHeight(4, height),
                }}
            >
                <Button
                    icon="trash-can-outline"
                    style={styles.button}
                    color={colors[theme].txt}
                    mode="outlined"
                    onPress={() => {
                        successHaptics(hapticsEnabled);
                        confirmationAction();
                    }}
                >
                    {confirmationButton.label}
                </Button>
                <Button
                    icon="close-circle-outline"
                    style={styles.button}
                    color={colors[theme].txt}
                    mode="outlined"
                    onPress={() => {
                        selectionHaptics(hapticsEnabled);
                        dismissAction();
                    }}
                >
                    {cancelText}
                </Button>
            </View>
        </Modal>
    );
};

const getStyleSheet = (theme: ThemeType, width: number, height: number) =>
    StyleSheet.create({
        modal: {
            backgroundColor: colors[theme].bg,
            marginLeft: calcFromWidth(40, width),
            marginRight: calcFromWidth(40, width),
            paddingVertical: calcFromHeight(15, height),
            borderRadius: 5,
            marginBottom: calcFromHeight(40, height),
            shadowOffset: {
                width: 10,
                height: 10,
            },
            elevation: 10,
        },
        text: {
            fontSize: getFontSize(2.5, width, height),
            marginHorizontal: 30,
            lineHeight: 28,
            marginTop: 0,
            textAlign: 'center',
            color: colors[theme].txt,
            marginBottom: calcFromHeight(15, height),
        },
        button: {
            borderColor: colors[theme].txt,
        },
    });

export default ConfirmationModal;
